import React, { Component } from 'react'

export default class OnlineWorksSerra extends Component {
  render() {
    return (
 <div class="modal" id="onlineworksserra" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-full" role="document">
        <div class="modal-content">
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
            <div class="modal-header">
                <h5 class="modal-title">Un incontro con Renato Serra - Alessandro Tamburini</h5>
            </div>
            <div class="modal-body p-4" id="result">
            Ci sei passato davanti infinite volte senza saperlo. La casa di Serra è incastonata e si confonde fra le altre di viale Carducci, a Cesena, sul lato opposto alle mura dove un tempo si alzava un doppio filare di pioppi che a Renato erano molto cari. La Storia non sta dietro di noi, ma sotto di noi. Negli immancabili resti romani e negli ordigni delle grandi guerre, affondati insieme nelle viscere della terra. A volte ce l’abbiamo davanti e ugualmente non riusciamo a vederla. Tutto ciò che non sappiamo ci impedisce di riconoscerla. <br/>
Avevi un’attenuante perché eri un ragazzino quando approdasti alle sponde della Romagna, profugo di lutti familiari e nuovi fortunosi assetti. A Cesena, città accogliente e magnanima con te fin dal primo giorno. Alla quinta ginnasio del Liceo “Vincenzo Monti”, dove ricordi di aver letto i bei versi di Moretti: “Piove. È mercoledì…”, che anni dopo avresti trascritto e mandato per posta a tua sorella facendola commuovere. Dove l’insegnante di lettere ti parlò certo anche dell’Esame, testo troppo complesso e distante da ciò che eri allora per poterlo fare tuo. Poi c’è che spesso nei confronti di un grande del passato lo sguardo dei concittadini risulta parziale, distratto. Ciò che è a lungo contiguo rischia di divenire scontato e sembra acquisito anche ciò che invece non si sa. Con chi si è distinto sugli altri, in provincia, si tende ad avere un atteggiamento che nel contempo ammira e sminuisce. Gli si augura fortuna, ma non troppa. Ci si compiace che il suo privato riveli delle debolezze, perché quelle altrui fanno sentire meno gravi e colpevoli le proprie. <br/>
Serra l’hai incontrato più tardi e partendo dalla fine. Dal momento e dai luoghi in cui la sua breve vita ha avuto termine. Avevi fatto base in Trentino, fra indubbie bellezze naturali e gente di natura troppo dissimile dalla tua. Una regione che è anche un museo a cielo aperto della Grande Guerra, e a Trento per anni hai costeggiato ogni giorno fino a non vederla più la forra del castello in cui venne garrotato Cesare Battisti. Serra lo aveva conosciuto a Cesena nel gennaio del '15, in occasione di una conferenza in cui aveva perorato la causa irredentista, tumultuoso e infine interrotto tra i fischi di un manipolo di contestatori. Come vicepresidente dell'associazione che ospitava la serata Serra aveva cercato invano di farli desistere. Aveva simpatizzato per Battisti, che sarebbe morto a sua volta in un giorno di luglio un anno dopo di lui. <br/>
Il Centenario della Grande Guerra è lo stesso dell’Esame di Serra e della sua morte. Un’occasione per tornare a parlare anche di lui come non ne conosceremo altre e forse in assoluto insuperabile. Ma nel Trentino ancora austriaco il conflitto ebbe inizio con un anno di anticipo sulla Romagna e perciò è toccato a Trento di aprire le celebrazioni. Per una diretta radiofonica ti hanno chiesto di parlare degli scrittori di fronte alla guerra ed è stato così che hai riscoperto l’autore nativo della città a cui sei tanto legato. Poi nella rilettura dell’Esame hai avuto una specie di folgorazione. Il senso di un’apertura, di un varco. L’intensità della voce. Anche una luminosa verità, che ti ha fatto apparire la posizione di Serra di una purezza unica. L’Italia non ha avuto figure della levatura di Russel, Rolland o Zweig a tuonare contro la guerra, ma scrittori e intellettuali pressoché tutti interventisti, che partivano volontari convinti, talvolta entusiasti. Pirandello, Emilio Lussu, Gadda, Ungaretti e Montale. Moretti scartato si arruolò come infermiere. Come ben sappiamo per alcuni la guerra era strumento di purificazione e  igiene del mondo. Papini esaltava il “caldo bagno di sangue nero dopo tanti umidicci e tiepidumi di lacrime materne”. Serra ti è sembrato il più lontano da suggestioni che oggi appaiono aberranti. Il più immune dalla sinistra fascinazione che la guerra esercitava su tutti. <br/>
Da quel momento nei viaggi che hai continuato a fare a Cesena, ricettacolo di affetti cari e preziosi come possono esserlo solo quelli di gioventù, hai preso a interessarti dell’uomo che sta dietro le pagine memorabili. Finché per un’altra delle tante congiunzioni di questo percorso ti è stato offerto di scrivere una storia su di lui, e sei stato contento e onorato di offrire un pur modesto contributo. Per tenere in vita qualcuno non c’è altro modo che rinnovare e tramandare la sua storia.
Hai cominciato nel solo modo che conosci: visitare i luoghi, parlare con le persone, raccogliere documenti. Costruirti una visione. Avevi già al tuo attivo letture, a cominciare dai decisivi saggi di Marino Biondi. Ma in giro per Cesena, fra il Teatro Bonci e il Palazzo del Capitano, non c’è uomo di penna che manchi di darti una propria immagine di Serra, un’informazione che ti mancava, il titolo di un libro che devi assolutamente leggere. Tu metti insieme dati, indizi. In principio operi come si fa per un’indagine e proprio a casa Serra fai il primo sopralluogo. <br/>
Quando ci abitava Renato, che a Cesena chiamavano solo per nome, era l’elegante palazzina residenza di una famiglia agiata, con la servitù, il calesse e i cavalli nella rimessa. Lo confermano l’androne austero, l'ampia scala a gradini di pietra larghi e bassi. A piano terra l'ingresso, con a sinistra lo studio del padre medico, uomo di cultura, che pubblicava saggi scientifici su riviste a diffusione nazionale. A destra i salotti dove una volta in settimana la madre riceveva le amiche come usava allora. Al primo piano le stanze che ti viene da immaginare vuote perché sai che il mobilio non è quello originale, a eccezione del tavolo di Renato trasferito qui dallo studiolo che stava alla Malatestiana. Al centro la luce della veranda affacciata sul giardino. Facile immaginarci Renato accostato alle vetrate, quando da ragazzo restava per ore a leggere libri presi dalla biblioteca paterna. O nella buona stagione proteso a cogliere suoni, profumi, lui che amava tanto i posti di osservazione sul mondo. Peccato non sia visitabile la sua stanza al secondo piano, che aveva la vista degli amati pioppi. Quella a lungo condivisa col fratello e che solo a ventidue anni, al ritorno dal servizio militare a Roma, poté avere tutta per sé. <br/>
Una vulgata che hai registrato in giro vuole che Serra non abbia abitato davvero in quella casa, che non sia stata molto importante per lui. L'infondatezza ti pare manifesta. Ci è vissuto a lungo, ci è tornato ogni volta che ha potuto. Negli scritti le si rivolge spesso con tenerezza, come nella lettera in cui la evoca così: “Se c’è un pensiero che mi sorrida un poco e mi rassereni, quello è, e solo, il pensiero della mia famiglia e dei miei cari e della mia casa. Proprio com’è adesso; non ricca né fortunata forse, ma cara a me più d’ogni altra cosa”. La scomparsa improvvisa della sorella e a breve distanza quella tragica del padre devastarono il suo nido in un modo che fa pensare a Pascoli, ma Renato non sarebbe vissuto abbastanza a lungo per elaborarlo. 
Su un angolo del giardino si inerpica un rosaio che si dice avrebbe piantato proprio lui, e fa da sfondo a una sua celebre foto. In quella come nelle poche altre che ci sono rimaste cerchi di carpire qualche mistero della persona. Altezza 1 e 82, molto rara allora. Fisico atletico dissimulato dalla postura rigida, da una fissità che rivela timidezza, o ritrosia. Il marcato naso aquilino che contrasta col disegno morbido della bocca. Lo sguardo svagato, assorto, talvolta un po' trasognato, che pare fissare qualcosa che solo lui può vedere. <br/>
Renato scende le scale, esce in strada e tu a dieci passi di distanza lo segui. Attraverso la Porta Cervese e Corso Umberto I, che oggi hanno altri nomi, compie quello che negli ultimi cinque anni della sua vita è stato il quotidiano tragitto. Fino a Piazza Bufalini, alla Malatestiana che lui chiama “casa dei libri” oppure “casa mia”. Lo vedi fermarsi davanti  alla porta “Alta, pesante, scura; con l’aria deserta che hanno le vecchie porte nelle mattine di domenica”. La porta che “si apre con un lungo e consolato sospiro”, “sul vuoto e sul silenzio che è mio, perché l’ho cercato”. 
L’incarico di bibliotecario gli era stato già offerto e lo aveva rifiutato in precedenza e c'è chi insinua difettasse nelle qualità più proprie del ruolo. Altri assicurano che lo ricoprì in modo forse poco zelante ma puntuale, scrupoloso. Proposte di acquisto, schedature di manoscritti, relazioni e rapporti annuali. A un corrispondente tedesco, ignorandone la lingua rispose in latino. Il suo studio una stanzetta piena di libri che ora non c'è più, sacrificata in un restauro, minuscola come l'unica foto che se ne conserva. Lì scriveva i suoi saggi più brillanti, i testi delle conferenze, le lettere. Le sensazioni che provava nel sapere che oltre la parete, a pochi metri di distanza, c’erano le aule del “Vincenzo Monti”, frequentato da enfant prodige prima di involarsi appena sedicenne verso l'Università di Bologna. Quelle che provi tu, iscritto settant'anni dopo nello stesso liceo, la cui vista ti suscita ogni volta ricordi a profusione. La sigaretta al mattino presto, col pacco di volantini sul braccio, davanti al portone ancora chiuso. La chiave passepartout fatta fare per poter andare ad amoreggiare in cantina con la tua bella, servita un giorno per aprire la porta della palestra e dare il via all'assemblea studentesca proibita dal severo Preside, quel Biagio Dradi Maraldi che fino a ieri non sapevi essere appassionato studioso di Serra. La Camera Ardente allestita nell'ingresso per un ragazzo della scuola morto in seguito a un terribile incidente d'auto. I compagni affranti, annichiliti dallo scandalo della morte giovane, rara nei nostri anni e invece mulino instancabile in quelli della guerra, così da cancellare generazioni intere.
Continui a seguire Renato. Lo riconosci per la corporatura alta e snella, se è inverno dal mantello nero col colletto di marmotta. La sera dopo cena va in palestra per tenersi in esercizio. Sbarra, parallele, manubri. È uno sportivo. Va a giocare  a tamburello e a pallone nello sferisterio comunale, in cima al colle della Rocca. Gira con una bici da corsa, è un buon nuotatore. Ma alla cura del corpo si accompagnano i cedimenti della volontà. Uscito dalla palestra Renato va dritto al circolo cittadino. Controlla che non ci sia suo padre. Siede al tavolo verde e gioca, e perde. È un male di famiglia, che non risparmia il padre e ha spinto uno zio materno a piantare tutto ed emigrare in Argentina. Renato gioca accanitamente, spesso fino all'alba. Accumula debiti che diventano un'ossessione. Anche per allontanarsi da quel costoso vizio progetta di continuo di andarsene, ma poi ci ricade sempre. Alla fine lo salveranno la madre e il fratello, che procureranno il denaro con un'ipoteca su parte dei beni di famiglia.
Tracce di Serra sono disseminate per tutta la città e adesso ogni volta che percorri il Corso, in prossimità del Duomo torni a figurarti la scena. Dicembre 1911, tranquilla mattina di lunedì. Vestito in giacca e gilèt di taglio inglese e da mezza stagione nonostante  il clima rigido, del tutto ignaro Renato è diretto alla Malatestiana. Forse ha il pensiero rivolto al saggio su Oriani che ha in tasca e a cui sta lavorando quando un tale gli si avventa contro con un’asta di ferro. È Luigi Tondi detto Sbigioli, ex ciclista e meccanico nonché commerciante di aratri. Lo colpisce alla testa con l’asta e non contento gli esplode contro cinque colpi di rivoltella. Un proiettile gli spacca l'osso di un braccio. Un altro non lo trapassa solo perché fermato da un borsellino pieno di monete, e una leggenda vuole che proprio quel giorno sua madre glielo avesse spostato da una tasca all'altra del panciotto. Sembra una scena da Far West e ancora di più quando Renato ancorché ferito estrae una Browning e fa fuoco a sua volta sull'assalitore. Per fortuna di entrambi non riesce a colpirlo. La gente fugge, si riavvicina dopo che l’eco dell’ultimo sparo si è spenta. I più coraggiosi si accostano attoniti al ferito per prestargli i primi soccorsi. Il ghiotto scandalo di provincia comincia a prendere forma nei resoconti che passano di bocca in bocca. 
Renato non si è sposato e nemmeno fidanzato. Ha avuto solo storie tormentate e clandestine e fino a pochi decenni fa le sue donne erano avvolte nel più fitto mistero. Ora se ne conoscono i nomi e le vicende e si sa che nella circostanza il Tondi lo accusava di avere una tresca con la moglie ventottenne Maria. Come si sa di un'altra, amata ma già promessa, per cui Renato soffrì e che tra l'altro gli costò la rottura con l'amico più caro. Era un uomo di fascino, per cui a quanto si dice le signore si facevano belle. Il più delle volte lui disdegnava le intellettuali e si perdeva dietro le sartine, le donne semplici che incarnavano il desiderio istintivo e diretto. Da ragazzo si dichiarava goffo e timido con l'altro sesso. Non si sarebbe aspettato una fama di dongiovanni e certo non la cercò mai. “C'è una piccola donna che ho amato, anche quella! Infinitamente” scriverà in una lettera, per la compagna di una relazione interrotta a quanto pare in segno di rispetto per il di lei marito inviato a combattere in Libia. Probabilmente la stessa evocata nel Diario di trincea con l’appellativo petit putain. Poi nelle stesse pagine troviamo Yudy, “la folle testa bruna, il collo bianco e un odore selvaggio”, e “la bambinaccia di Cesena”. Tracce di desideri carnali violenti, con sguardi spietati su di loro e su se stesso, come quello rivolto a una donna che non ha avuto: “ti fa sorridere ma non la puoi disprezzare finché non hai eiaculato”.
Segui ancora Renato per i luoghi di Cesena a lui più cari, come rivelano i suoi scritti. Risali con lui l'acciottolato che conduce alla Porta Montanara, oltre la Rocca, cornice della veduta delle colline e della Chiesa dei Cappuccini. Quella che come una sorta di valico, o di siepe leopardiana, lui vedeva “porta aperta sul cielo; e di là il mondo”. Attraversi Piazza del Popolo, allora Vittorio Emanuele, e ripensi a Remirro de Orco che vi fu fatto decapitare dal Duca Valentino, come racconta Machiavelli nelle pagine che hai letto tante volte ai tuoi studenti. Scorti Renato fino al fiume, al Ponte Vecchio “che valica il Savio col grande arco quasi romano”, una delle sue passeggiate preferite. Ancora in cerca di una visuale, di un orizzonte ampio e profondo che da lì spazia fra i colli di Bertinoro, la torre di Roversano e i lontani profili dell’Appennino. Lo osservi e seguendo la direzione del suo sguardo, mentre provi a indovinare quella dei suoi pensieri ritrovi l’argine prativo, i pomeriggi che vi trascorrevi insieme a un primo amore felice e ineguagliabile, come l’estasi della giovinezza. Parlare senza sapere di cosa. Il cielo che ruotava sopra di lei. Una maglietta rossa a esaltare il biancore ammaliante del suo collo nudo. <br/>
Quando vuole andare più lontano Renato inforca la sua Peugeot, fra le prime bici da corsa che si vedevano all’epoca. Manubrio molto basso. Il massimo della libertà individuale quando auto e moto erano ancora rare. Strumento capace di gittate oggi impensabili. Renato ci si spostava agilmente fra Cesena e Bologna. Poteva fare una scappata nel capoluogo emiliano per vedere passare il Giro d’Italia, o programmare una scampagnata a Firenze attraverso il Passo del Muraglione. Non era il solo. Tutti andavano in bicicletta e alcuni ne scrivevano, come il conterraneo e amico Panzini che nella Lanterna di Diogene narra di un viaggio fra Milano e Bellaria, più tardi imitato da Guareschi.. <br/>
Renato imbocca la Cervese e pedala fino al mare, oppure gira per le campagne. Anche col manubrio basso non ha paura dei ruvidi sterrati. Si ferma a una fontana o a un bar. Parla con la gente. Prova sensazioni che cadono come semi nella terra fresca, destinati a germogliare e ad assumere una rilevanza decisiva. <br/>
Dopo lo scoppio del conflitto è entrato in una crisi profonda. L’Italia ne è ancora fuori ma la guerra si avvicina e i colpi secchi e cadenzati del tamburello gli sembrano risuonare come spari nello sferisterio. La carta che al tavolo da gioco gli serve per chiudere il full di colpo non è più così importante e anche con le donne la corrente si abbassa. È un tormento che macera e trova sfogo pochi mesi dopo con la stesura del suo testo chiave, dettato da una necessità espressiva assoluta. <br/>
Renato si mette a scrivere. In principio pare dialogare ad alta voce. Passa in rassegna le ragioni politiche e ideologiche dei fautori della guerra e nessuna gli appare determinante. Smaschera storture e mistificazioni. Alterna reticenze, affondo improvvisi, autoconfutazioni. L’esito è il senso di inutilità della guerra. Il momentaneo approdo a uno scetticismo incrudito dalla propria condizione di solitudine infeconda. Lo stile è sinuoso, carsico. L'andamento richiama quello di un partitura musicale, con in sottofondo il tuono dei cannoni di chi la guerra la sta già combattendo. Fino allo scarto decisivo e inatteso, che elude le motivazioni razionali e spalanca alla vista un diverso orizzonte. <br/>
Non è più tempo di guardare la propria ombra stampata sul muro. Bisogna uscire da quel “carcere d’inchiostro”. Le parole non bastano più  Le parole dicono una verità futile, sgominata da un'impennata di sentire vibrante. Per seguire l'improvvisa illuminazione anche lo stile si trasforma, si distende nella forma della letteratura, a tratti della poesia. Come quella che si trova sparsa quasi con noncuranza nelle lettere.
“Si ha voglia di camminare, di andare. Ritrovo il contatto col mondo e con gli altri uomini. Che mi stanno dietro, che possono venire con me”. <br/>
“Le cose che io penso sono determinate e comuni. Quanto all’umanità, conosco solo quelli che ho vicini: quelli che mi fermavano quest’estate, quando passavo in bicicletta, in riva al mare, o per lo stradone infocato”. <br/>
“Purché si vada! Dietro di me son tutti fratelli, quelli che vengono, anche se non li vedo o non li conosco bene. Mi contento di quello che abbiamo di comune, più forte di tutte le divisioni. Mi contento della strada che dovremo fare insieme”.
Niente di eroico o di tragico. Nessuna enfasi retorica. Un nitore da rivelazione: “Andare insieme”. Un sentimento di fratellanza vicino a Ungaretti (“Di che reggimento siete/fratelli?”). La guerra che istituisce una comunicazione più immediata e profonda, primigenia fra uomo e uomo. <br/>
Partecipare significa unirsi e mescolarsi agli altri, alla gente della sua Romagna. Perché “se ci tocca, si va tutti insieme questa volta”. Vivere una vita più autentica perché simile a quella delle persone comuni, nell’uguaglianza del destino e del sacrificio. L'uomo, lui stesso, esiste solo nel rapporto con gli altri. È nella comunanza con gli altri che ci si scopre, che si è davvero uomini. <br/>
Allora, terminata la tua personale inchiesta, torni a domandarti chi sia questo Renato Serra. Qualcuno lo ha detto letterato di provincia, troppo attaccato a Cesena, senza il coraggio di affrontare la grande città. Tu ci vedi piuttosto la ricerca di altri valori, per lui più autentici e irrinunciabili. Vedi un irregolare, un indisciplinato che non ambiva a far parte della società letteraria dell’epoca, come è stato anche dopo per altri fra i migliori, da Silvio d’Arzo a Delfini, da Bilenchi a Fenoglio, nella centralità della provincia nel nostro novecento. Vedi un uomo combattuto da potenti antinomie. Inquietudine e pigrizia. Puro e vivace talento unito al piacere dello svago, dell’ozio, della pura contemplazione. Istanze di indipendenza e bisogno di sentirsi accolto e amato. Una malinconia imperscrutabile forse anche a lui stesso. Un grande senso dell’amicizia e la generosità con gli amici. L’attrazione che esercitavano su di lui le persone semplici, donne e uomini, come Ferruccio Mazzocchi, meccanico, con cui parlava di bici, sport e ragazze e che gli insegnò a ballare in un vecchio magazzino. Un’assoluta mancanza di vanità, dote davvero rara fra i suoi pari. Lo scarso peso dato al proprio nome stampato su un giornale, se non per la soddisfazione che ne veniva alla madre. Vedi uno che amava vivere più che scrivere, come si è detto, o almeno altrettanto. 
Messo di fronte all’evento estremo toccato alla sua generazione, e a una scelta cruciale, questo giovane uomo originale, ritroso, forse un po’ viziato, ha saputo dare una risposta esemplare, quasi a riscattare anche tutto ciò che non aveva fatto prima, e che dopo non avrebbe potuto più. Poi ha agito di conseguenza con determinazione pacata e assoluta. Incurante di un brutto incidente che lo ha reso sordo da un orecchio. Risoluto a tornare subito al fronte per seguire il destino che si è assegnato. L’assalto alla trincea nemica e la fatale leggerezza di sporgersi. Il proiettile alla tempia che lo fulmina. La precipitosa ritirata degli altri, e all’appello il tenente Serra manca. Caduto o rimasto prigioniero non è ancora dato sapere. E ti viene da pensare al suo stile, alla sua compostezza, al suo stupore per la bellezza semplice e inaspettata della natura mentre rileggi di come fu ritrovato il corpo. <br/>
Sono trascorsi tre giorni e sulla terra di nessuno, un campo sassoso in pendenza coltivato a grano, si affaccia il caporale riminese Averardo Marchetti. Al di là del campo ha intravisto un albero carico di susine mature, che fanno gola nel torrido pomeriggio di luglio. Comincia ad avanzare carponi, fra le buche delle granate che hanno aperto squarci fra le spighe mature non raccolte. Così si imbatte nel corpo di Renato Serra. Supino, con il capo rivolto al pendio. Il volto appare sorridente. L’uniforme è intatta e il giovane caporale lo riconosce subito per i distintivi di grado. Per quei filetti d’argento da cui il cecchino austriaco ha riconosciuto un ufficiale. 
Poco più tardi la salma sarà recuperata da un portaferiti anche lui romagnolo, di Pievesistina. Renato passava in bicicletta anche di lì, uscendo da Porta Ravegnana,  oggi Porta Trova, attraverso la via Emilia e le sbarre della ferrovia, poi via verso Ronta, Martorano, i piccoli abitati incorniciati dal verde della campagna intorno. 
I primi a prendersi cura di lui dopo che ha perso la vita sono due persone semplici, della sua terra, simili a tante altre con le quali e per le quali aveva scelto di partire pochi mesi prima. 

                   
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
        </div>
    </div>
</div>


    )
  }
}

import React, { Component } from 'react'

export default class OnlineWorksMeccanico extends Component {
  render() {
    return (
 <div class="modal" id="onlineworksmeccanico" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-full" role="document">
        <div class="modal-content">
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
            <div class="modal-header">
                <h5 class="modal-title">Un meccanico - Alessandro Tamburini</h5>
            </div>
            <div class="modal-body-testionline p-4" id="result">

L’appartamento era al primo piano di una modesta palazzina di periferia, e lo condividevo con due studenti universitari venuti come me da fuori città. Sopra di noi abitava la proprietaria, una vedova con nove figli maschi in scala, dagli otto anni del più piccolo ai trenta del più grande. Donna di grande iniziativa, materna anche con noi inquilini, almeno una volta in settimana ci offriva un piattone di pasta asciutta, vino rosso sfuso e mele a volontà. Seduto a quella lunga tavolata avevo l’impressione di trovarmi alla mensa di un convitto, e vestivo l’abito mentale del viandante che ha trovato riparo in una sera invernale.<br/>
L’inverno c’era davvero, rigido come poteva esserlo trent’anni fa in una città alpina. Ci ero finito per caso. Avevo inviato il mio modesto curriculum a una dozzina di cliniche veterinarie, e da lì mi era giunta l’unica risposta e offerta di lavoro. Il mio tirocinio prevedeva però che facessi solo visite domiciliari, per cui dovevo andare in giro per le valli tutto il giorno e a volte anche la notte, come potevano richiedere un parto urgente in una stalla o altre emergenze simili. <br/>
Oltre alle difficoltà causate dalla mia limitata esperienza sul campo, c’era che la mia Ford Escort color amaranto acquistata di terza mano veniva messa a dura prova da spesso impervi percorsi di montagna, e necessitava di urgenti riparazioni. Perciò durante una di quelle cene mi rivolsi al fratello maggiore, che con la barba scura e la fronte ampia pareva un giovane frate. Aveva per caso un bravo e non troppo esoso meccanico da consigliarmi? E lui senza esitare un istante: “Giuliano!”. <br/>
Un tratto distintivo dell’intera famiglia era la laconicità, cosicché non ricevetti le indicazioni che avrei desiderato. Niente telefono, indirizzo vago, incerti anche gli orari. Giuliano lavorava nel garage privato di un grande condominio, di fianco alle caserme, e verso sera era quasi sempre lì. Trascrissi alcune parole chiave sul tovagliolo di carta e già il giorno seguente tentai una spedizione.  <br/>
Conoscevo poco la città e mi persi più volte prima di trovare il quartiere indicato, un dedalo di uguali strade e condomini che cominciai a perlustrare ai venti all’ora con occhi scrutatori. Mi concentrai sugli edifici che fronteggiavano il muro della caserma, con la lunga fila di finestre delle camerate da cui faceva capolino qualche militare con la sigaretta accesa. <br/>
Entravo e uscivo dai grandi cortili, ma niente nemmeno lì. Solo parcheggi di auto, nessuno a cui chiedere. Mi risolsi a fare un estremo tentativo a piedi, ed ero ormai prossimo ad arrendermi quando vidi un riflesso provenire dalla rampa di un garage, scesi cauto nella penombra, bussai alla porta da cui trapelava quella crepa di luce. <br/>
Mi aspettavo di trovarmi di fronte un uomo fatto, o almeno con più anni di me. Venne invece ad aprirmi un ragazzetto smilzo e imberbe, coi capelli biondo stoppa e un ciuffo sugli occhi che ne accentuava l’espressione corrucciata, un grosso naso aquilino arrossato dal freddo. Era di certo sotto zero là sotto, ma indossava dei jeans lisi e una camicia di flanella. Non riuscivo a vedere molto nello spazio stretto fra la parete e l’auto sostenuta a mezza altezza dal sollevatore idraulico. <br/>
Feci il nome di chi mi aveva mandato da lui, che non gli suscitò alcuna reazione, e accennai ai lavori più urgenti di cui necessitava la mia auto. Volle sapere il modello e il chilometraggio, poi disse di portargliela il giorno seguente. Non più di dieci parole in tutto. Quando gli tesi la mano mi mostrò le palme sporche di grasso, e rispose solo con un cenno del capo al mio anche troppo caloroso saluto. <br/> 
Me ne andai poco convinto, intenzionato a ridurre al minimo il rapporto con quel giovane burbero, che tale si dimostrò anche al momento in cui mi ripresentai per consegnargli l’auto. Mi davo pensiero anche del preventivo, ma per qualche oscura ragione non osavo chiederglielo. Ogni timore venne però fugato al momento del ritiro. Il ragazzo scarabocchiò qualcosa a matita su un blocchetto, poi ebbe un’esitazione prima di dire quanto gli dovevo, e per farlo ricorse per la prima volta al dialetto del luogo. <br/> 
La cifra mi sembrò irrisorio e gli ricordai le numerose riparazioni convenute. Compresi di aver commesso un errore da come senza darmi risposta si chinò sul cofano dell’auto di turno, mentre la mia l’aveva già spostata fuori. Quando gli porsi le banconote contate fece segno di infilargliele nella tasca posteriore dei jeans. Avrei voluto esprimere la mia soddisfazione, ma qualcosa me lo impediva. <br/>
Il ragazzo sembrava circondato da una bolla protettiva che respingeva tutto l’inessenziale. Sobrietà, timidezza, villania: non lo sapevo ancora. Intravedevo un’indole selvatica e indocile nel modo in cui drizzava le spalle, nel volto sempre un po’ aggrottato, con quel naso da indiano d’America, in cui avevo tardato a notare gli occhi azzurri e luminosi perché incrociavano di rado i miei. <br/>
Tornai prima del previsto, causa la nuova urgenza della cinghia di distribuzione che Giuliano sostituì per la metà di quanto mi avevano prospettato altrove, sempre con quei modi scostanti che però non mi infastidivano. Vi coglievo una sorta di familiarità e insieme di concessione, come se non fosse scontato che mi accettasse come cliente, mentre da parte mia avevo già superato ogni riserva nei suoi confronti. <br/>
A costi così contenuti potevo permettermi la revisione che la mia vecchia auto richiedeva da tempo, e per un periodo lo vidi spesso. La sua estrema economia di parole contribuiva alla fiducia che mi ispirava. Non faceva domande prima di intervenire sui guasti, anche quelli che gli segnalavo in modo confuso e improprio. Non commentava e non dava spiegazioni. Era sempre padrone della situazione, oppure molto bravo a sembrarlo.   <br/> 
Fu quasi rassicurante scoprire che almeno la dote della puntualità gli mancava del tutto. Una volta dovetti aspettarlo per un’ora nel seminterrato, battendo i piedi per il freddo. Lo ricordo bene perché ero esausto a causa del parto cesareo di una vacca, che avevo dovuto praticare all’alba dopo una notte di tregenda. Ma lui non ritenne necessario scusarsi, nemmeno accelerare il passo quando mi vide mezzo rattrappito davanti alla porta del garage, e per quanto io fossi spazientito non osai protestare come mi ero ripromesso. Per giunta non aveva quasi mai preparato il conto al momento della riconsegna, e per pagare bisognava tornare una seconda volta. <br/>
Capitava che si radunassero diversi clienti venuti a portare o ritirare l’auto, o per prendere accordi. Neanche loro erano molto loquaci e familiarizzai un po’ solo con un meridionale sulla cinquantina, che mi incuriosì perché arrivava alla guida di una vecchia e malandata Porsche Carrera. Lunghi capelli brizzolati, folti baffi ingialliti dalla nicotina delle sigarette che fumava in continuazione, commentava con battute spiritose il fatto che sia lui che sua auto avevano conosciuto giorni migliori. Lo confermavano i mocassini sformati, la giacchetta inadeguata al freddo. Fu lui a informarmi che Giuliano lavorava in un’officina e dopo le otto ore proseguiva lì, a volte fino a sera inoltrata. Mi disse poi che aveva vent’anni appena compiuti, e concordammo che a seconda dei momenti ne dimostrava qualcuno in meno o molti di più. <br/>
Per certe riparazioni veloci la riconsegna poteva essere immediata e nell'attesa mi guardavo attorno. Strideva il contrasto fra la trascuratezza dell'ambiente e la cura degli attrezzi, le file ordinate di chiavi sul banco da lavoro, la strumentazione elettronica nell'apposito carrello. Un’avara luce dal soffitto rischiarava appena l’ambiente, ma un faro direzionale forniva a Giuliano l’illuminazione ottimale nel punto richiesto. Non vi erano caloriferi e se lui si scaldava lavorando, i clienti pativano il freddo. <br/>
Ma le giornate si allungavano e il passaggio all’ora legale portò allo scoperto la primavera. Se in gennaio alle sei del pomeriggio sembrava notte e il quartiere era deserto, ora il cortile era campo di gioco dei bambini, e i loro strepiti si confondevano con le strida delle rondini che intrecciavano i loro voli sghembi tra le pareti dei condomini. Dalle finestre delle camerate usciva il sonoro delle radio e facevano capolino soldati con la divisa aperta, che ridevano per una battuta lanciata dal più sfacciato al passaggio di una ragazza. E con la nuova stagione ne comparve una anche da Giuliano. <br/>
Capelli scuri legati a coda di cavallo, faccia pulita e sguardo schivo, stava seduta su uno sgabello nell’angolo del garage, e pensai fosse lì per l’auto a cui lui stava lavorando. Ma la rividi anche la volta dopo, gentile nel ricambiare il mio saluto ma poco incline a spingersi oltre. Per questo pensai subito a un’affinità e a un’intesa fra loro due. <br/>
Veniva per Giuliano, non per il meccanico, anche se almeno in mia presenza non si scambiavano parola. Nemmeno lo guardava. Le bastava restare lì con lui. Era snella e ben fatta, vestita alla buona e senza un filo di trucco, con una sua grazia composta, e per quanto fosse riservata a momenti le si leggeva nello sguardo che quel ragazzo per lei era già il centro di tutto. Pareva che stesse lì per vegliarlo, per dimostrare di essere la donna adatta a lui attraverso quel noviziato di caparbietà e dedizione. <br/>
C’era ragione di credere che fosse una strategia adatta a conquistare un giovane meccanico che sgobbava da mattina a sera come per un suo segreto scopo, quasi non ci fosse nient’altro al mondo, nemmeno lei, almeno per il momento. Nell’osservarlo mi domandavo se si sacrificava pensando al futuro o se quella dura disciplina fosse una prassi obbligata per lui, come diceva la sua espressione sempre severa  e risoluta. <br/>


Anche se ero ormai diventato un cliente fisso Giuliano manteneva il suo atteggiamento dispotico. Una sera che scesi con l’auto nel garage senza il preavviso che lui richiedeva si arrabbiò di brutto e mi scacciò con improperi tali da offendere chiunque. Ma come accadeva per i suoi abituali ritardi restai impassibile, sotto l’influenza di una inesplicabile mansuetudine. <br/>
Altrettanto costanti erano l’economicità e il pudore che manifestava nei confronti del denaro. Nel conto che redigeva in ritardo e di malavoglia, al costo dei ricambi che si procurava quasi sempre dagli auto recuperi ne aggiungeva uno per la manodopera pari a un terzo delle tariffe che avevo sperimentato altrove, tanto che più di una volta, a dispetto dei miei risicati introiti, ritenni doveroso dargli di più. Gli infilavo le banconote nella tasca dei jeans, senza aspettare il resto. Lui in ogni caso non si scomponeva, non andava oltre un cenno di neutra accettazione. <br/>
Mi ero già reso conto che parlare meno possibile era un tratto comune fra gli abitanti del luogo. Per timidezza, diffidenza, orgoglio, sembravano ispirarsi a una figura di uomo forte e silenzioso, con frequente ricorso agli alcolici. Ma Giuliano li superava tutti, oltre a essere astemio, come dichiarò la volta che gli portai in dono un paio di  bottiglie. <br/>
Era impensabile domandargli o che lui raccontasse di sé, ma col tempo e per caso venivo a conoscenza di qualcosa. Una sera mi chiese di andare a riprendermi l’auto nell’officina dove lavorava di giorno per dargli poi un passaggio al suo garage, e lungo il tragitto si lasciò sfuggire che il suo capo ne capiva meno di lui, ma pretendeva di avere sempre ragione. <br/>
Più tardi seppi da altri clienti che aveva trovato un posto migliore, in un'officina più grande dove di lì a poco acquistai la mia nuova auto, una Ford Taunus a sua volta di terza mano ma tenuta bene, con la strategia che poi avrei seguito sempre: berline di media cilindrata, che usate si deprezzano molto, prese a 70-80 mila chilometri e portate a 200 mila. Giuliano invece non aveva un'auto propria. Per i suoi brevi percorsi si serviva di quelle dei clienti, così ne approfittava per provarle, oppure di certe vetture malconce che rimetteva in sesto un po’ alla volta, usava per un periodo e poi rivendeva con qualche profitto. <br/>
La ragazza intanto continuava a venire, e mostrava un po’ più di confidenza con lui. Non a parole, ma nel modo in cui si muoveva nel garage, sedeva a gambe incrociate e sfogliava una rivista. Una sera vi trovai solo lei, perché Giuliano era affacendato altrove e le aveva affidato la chiave dell’auto da riconsegnarmi. <br/>
“Io sono Luisa” disse, e anche se ci eravamo già visti più volte ci stringemmo la mano. Le domandai se aveva bisogno di un passaggio. Scosse il capo facendo ballare la lunga coda di cavallo e ringraziò con un mezzo sorriso. Sembrava sempre distratta da qualcosa, o presa da un pensiero. Se avesse accettato mi sarei stupito. In seguito mi capitò di vederla meno di frequente, e solo per fugaci apparizioni. <br/>
Era trascorso circa un anno quando dopo avermi riparato l’auto Giuliano mi chiese se potevo accompagnarlo a casa. Si mise lui alla guida, forse per un'ultima verifica, e arrivammo a un condominio nella periferia opposta della città. Era appena sceso quando una figura femminile si affacciò dal balcone del primo piano  per dirgli qualcosa.  <br/>
Non fui certo di riconoscerla, perché indossava un’ampia tuta da casa e aveva i capelli sciolti. Senza scomporsi e senza convenevoli come suo solito, lui mi chiese di portarlo dove aveva un’altra auto da ritirare. Così lungo il tragitto potei domandargli se la ragazza al balcone fosse la stessa del garage. Era proprio Luisa, si erano sposati e c’era una bambina in arrivo, una popa, ammise nel dialetto a cui ricorreva per gli argomenti difficili. <br/>
Gli feci i complimenti e in tono gioviale dissi che anch’io avevo conosciuto una ragazza, che si chiamava Irene, era un’agronoma e ci eravamo conosciuti perché l’aveva morsa un cane che si temeva fosse affetto da rabbia e invece per sua fortuna non lo era. Ma come succedeva per qualunque comunicazione di natura privata non capivo neanche se Giuliano mi stesse ascoltando. <br/>
Continuavo ad andare da lui, a dispetto delle non poche complicazioni. Una volta lo cercai per diverse sere di fila al garage senza trovarlo, con lunghe e vane attese in cui mi ritrovai in compagnia del tipo della Porsche, che aveva a sua volta urgenza di incontrarlo. <br/>
Mi sembrò più malmesso dell’ultima volta che ci eravamo visti. Aveva due brutti calamari sotto gli occhi e consumava la sigaretta fino a bruciare il filtro. Per giustificare lo sportello di fortuna montato sulla sua Carrera, che spiccava per il colore diverso dal resto, abbandonato il consueto tono ironico parlò con amaro sarcasmo della separazione dalla moglie, e delle difficoltà economiche che gliene erano conseguite. <br/>
Per un certo periodo la latitanza di Giuliano si accentuò e mi suscitò un nuovo moto di ribellione nei confronti delle sue stranezze, o piuttosto dalla mia dipendenza da lui. Mi rivolsi perciò a un’officina che mi era comoda, perché a due passi dalla casa in cui ero appena andato ad abitare con la mia compagna. Ma fu un insuccesso totale. Giuliano tratteneva l’auto per un paio di giorni al massimo, mentre lì ci volle più di una settimana. Non mi piacque l’atteggiamento solerte e artificioso del meccanico di turno, e ancora meno il conto a dir poco esoso. <br/>
La Taunus era una roccia e passarono diversi mesi prima che avessi occasione di tornare da Giuliano, cosa che desideravo come forma di ravvedimento, oltre che per sincerarmi che lavorasse ancora lì, perché per quanto ne sapevo poteva anche essere sparito chissà dove. <br/>
Anticipai apposta il successivo tagliando e stavolta lo trovai al primo colpo, un giorno di inizio autunno, con le mani nel motore di un’auto. Indossava una delle solite camicie a quadri in stile tagliaboschi e sollevò appena il capo al mio saluto. Com’era di prassi proseguì il lavoro finché gli fece comodo, poi con calma si pulì le mani in uno straccio e mi diede ascolto. Presi gli accordi me ne andai, e mentre risalivo la rampa del garage mi sentii liberato da un peso. <br/>
Lavoravo sempre in giro per le valli, anche nei fine settimana mi mettevo spesso in strada per tornare dalle mie parti a trovare parenti e amici, per cui l'affidabilità dell’auto mi premeva molto. E Giuliano sapeva trasmettere un senso di sicurezza che mi liberava dal timore di un possibile guasto. Sembrava capace di prevenirlo o scoprirlo sul nascere, di tenere sotto controllo l’usura di ogni pezzo o ingranaggio. <br/>
Era il suo modo di lavorare a persuadermi. I suoi movimenti intorno all’auto da riparare erano precisi e fluidi, col ritmo di un esercizio di danza. Non una sola volta l’ho visto in difficoltà, o dare segni di impazienza. Sceglieva senza esitare lo strumento giusto, e senza mai impugnare il martello. Cercava la posizione più vantaggiosa, mezzo steso sul vano motore dell’auto, con la precisione di un giocatore di biliardo. Non sottovalutava gli interventi da fare, né li ingigantiva come fanno spesso i meccanici. Parlava solo quando poteva essere conciso e chiaro. La padronanza con cui sollevava il cofano o sedeva al volante andava molto oltre la semplice pratica professionale. Quando guidava la mia auto, per riaccompagnare a casa me o lui, motore e cambio gli rispondevano docili, come se riconoscessero la mano di un addestratore. Da un certo momento in poi smise anche di farmi la distinta con le riparazioni effettuate, i ricambi e la manodopera. Diceva la cifra e basta, sempre nel dialetto che lo aiutava a vincere il disagio. <br/>  


Al di là della mia personale visione, Giuliano era bravo davvero. Era passato alle dipendenze di una grossa concessionaria di auto e nel giro di un anno ne diventò capo officina. Mi capitò di intravederlo, all’interno del capannone in cui una squadra di meccanici lavorava su diverse auto alla volta. Indossava una fiammante tuta blu per cui tardai a riconoscerlo, ma si spostava da una postazione all’altra con gli stessi movimenti precisi e armonici, solo a raggio più ampio. Aveva conservato l’aria da ragazzino ed era più giovane di molti suoi sottoposti. Forse per questo si era fatto crescere una barba biondastra, ancora rada sulle gote. <br/>
Nel suo nuovo ruolo doveva seguire corsi di aggiornamento che lo portavano fuori regione per settimane intere, e mi domandavo se mantenesse lo stesso comportamento in mezzo a sconosciuti colleghi di altre città, nei serali rientri in albergo. Per il resto rispettava l’abituale ordine del giorno. <br/>
Intanto avevo avuto anch’io qualche avanzamento professionale. Un paio di giorni in settimana restavo a fare visite in ambulatorio ed ero ben felice di rientrare a casa a un’ora più ragionevole, perché ci era nata da poco una bambina. Qualche volta Irene si lamentava del tempo che sprecavo nel fare la posta a Giuliano al garage, e capitava anche a me di imprecare per certe lunghe attese, ma le sopportavo e non considerai più l'ipotesi di rivolgermi altrove.  <br/>
Anche la svolta che speravo arrivasse con l’avvento del cellulare si rivelò illusoria. Giuliano lo teneva quasi sempre spento e il più delle volte mi risolvevo ad andare di persona come al solito. Nel migliore dei casi rispondeva, non ricordo una sola volta in cui mi abbia chiamato lui. <br/>
Divennero però possibili dei contatti a distanza, nelle rare occasioni in cui dovetti affrontare degli imprevisti mentre ero lontano. Ricordo la sicurezza con cui al telefono mi dissuase da un intervento che un meccanico veneto reputava urgente. In un’altra trasferta lo consultai sul brutto rumore che usciva dall’avantreno, e dopo avermi chiesto di sollevare il cofano ne individuò la causa come se vedesse meglio di me ciò che avevo sotto gli occhi. <br/>
Fedele alla mia politica di acquistare berline usate in buono stato, in quegli anni passai a una Ford Sierra e poi a una Mondeo, e quando mi presentavo con una nuova auto Giuliano non dava giudizi. Lasciava cadere le domande che gli rivolgevo cercando la conferma di aver fatto un buon affare. Prendeva in consegna l'auto con neutralità assoluta. <br/>
Far bene e non far sapere era il principio ispiratore di molta gente del luogo, a cui Giuliano aggiungeva un’indole rustica e selvatica, una durezza che mi ero convinto gli facesse da scudo per pararsi da emozioni che rifuggiva, perché lo avrebbero fatto sentire debole. E ancora l'ostinazione, che a momenti traspariva dai suoi occhi azzurri e sfuggenti, la spinta tenace verso una meta a cui continuavo a immaginare fosse rivolto quel suo tanto severo regime di vita.  <br/>
Pensai di aver avuto ragione alla notizia che avrebbe lasciato il garage, che stava per acquistare una casa. Era una sera d’inverno, mi aveva riconsegnato la Mondeo e lo stavo accompagnando quando me lo annunciò, e subito cominciò a parlarne con una loquacità che mi lasciò sbalordito. <br/>
La casa era sulle prime falde del monte sovrastante la città, e c'erano lavori da fare, ma contava di andarci a vivere con la famiglia prima dell’estate. Si trattava di interventi grossi perché l’edificio era in uno stato disastroso. Ci era morta tempo prima una vecchia matta che teneva galline, conigli e soprattutto gatti, ne aveva raccolti più di cento e qualcuno vi alloggiava ancora. <br/>
Cercai di capire se potevo dargli qualche indicazione utile al riguardo, ma gli premeva di più proseguire nel suo concitato resoconto. La vecchia aveva accumulato montagne di stracci, cartoni, bottiglie, lattine, giornali, rifiuti di ogni tipo. C’era da disinfestare, da rifare la scala interna, e bisognava poi affrontare il gran lavoro necessario per installare la sua officina a piano terra. <br/>
Con una altrettanto inedita urgenza nella voce mi ragguagliava sulle ingenti spese che avrebbe dovuto accollarsi, sui maggiori ostacoli da affrontare, e continuò anche quando arrestai l’auto sotto la sua attuale abitazione. Non dava segno di voler scendere e dopo un po’ mi decisi a spegnere il motore. Nella penombra del cortile, col freddo pungente che pian piano avvolgeva l’abitacolo, ripeteva ciò che aveva già detto della vecchia e dei suoi gatti, degli intenti che si era prefissato, delle incognite che gli davano le maggiori preoccupazioni. <br/>
Ricordo che avevo fretta, perché sebbene controvoglia dovevo presentarmi a una cena coi colleghi della clinica, ma quel flusso era troppo eccezionale perché potessi interromperlo. <br/>
A trarmi d’impaccio fu Luisa che lo chiamò a gran voce dal balcone, con accanto la bambina che vedevo per la prima volta. Solo allora lui si riscosse, sembrò rendersi conto della foga a cui si era lasciato andare e troncata a metà una frase se ne andò senza nemmeno un cenno di saluto. Col risultato che durante la cena fui più volte distratto dalle immagini di quella casa, per la quale Giuliano aveva speso in mezz’ora più parole di tutte quelle che gli avevo sentito pronunciare negli anni precedenti. <br/>
Di lì a poco accompagnai al garage un amico che voleva diventare suo cliente. La presenza estranea impedì a Giuliano di tornare sull’argomento, ma con insospettabile perizia riuscì a darmi a voce bassa i principali aggiornamenti: i lavori iniziati e ancora più complicati del previsto, l’aumento dei costi conseguente, la sua ferma intenzione di avere comunque la casa entro luglio. Il mio amico mi fece sapere poi che non si erano intesi, e che quel meccanico gli era sembrato davvero uno strano tipo. <br/>
Col pretesto di una piccola riparazione tornai da lui per sapere dove trovarlo quando avesse lasciato per sempre il garage. Era intento a sostituire il tubo di scarico di un’auto che sul sollevatore idraulico sfiorava il soffitto, e riprese subito a parlarmi della casa. Sembrava desse voce a un monologo che nella sua testa non si era mai interrotto dall’ultima volta che ero stato lì, e mi convinsi ancora di più che per lui si trattava della meta agognata, a cui erano finalizzati tutti i suoi sacrifici. <br/>
Era un fiume in piena e cercavo di arginarlo con delle domande precise, ma lui andava avanti per suo conto, riesaminava ostacoli vecchi e nuovi e le misure con cui contava di superarli. A momenti lasciava trasparire una rabbia sorda contro le forze avverse che minacciavano di soverchiarlo, ma poi tornava a prevalere la fiducia, e quando mi decisi a salutarlo ribadì che avrebbe preso possesso della nuova casa a fine luglio, che nel periodo di ferie dalla concessionaria avrebbe messo a posto tutto. <br/>
Mi feci spiegare bene come arrivarci, perché non mi fidavo del contatto telefonico. Poi rivolsi uno sguardo di commiato al garage, e fu come scattargli una fotografia a futura memoria. Le pareti venate di crepe, la luce fioca e spiovente come nelle cucine di una volta, e quella del faretto che lo attraversava come nella scena di un’Annunciazione. Avevo sempre percepito qualcosa di familiare nel suo odore, e solo allora riconobbi quello del sottoscala dove mi nascondevo da bambino, a casa dei miei: sentori di cantina e di muffa, del grasso usato per la catena delle bici. <br/>
Quando mi misi al volante erano le nove di sera passate ma nel cielo resisteva un chiarore innaturale, da giorno più lungo dell’anno, e di anni ne erano trascorsi quindici da quando Giuliano mi aveva aperto la porta del garage per la prima volta. <br/>


Tra un incontro e l’altro potevano passare diversi mesi, nei quali quasi mi dimenticavo dell’esistenza di Giuliano, ma quella volta no, curioso com’ero di vedere la sua casa nuova. Per diverso tempo però non ebbi bisogno di lui, e non mi pareva il caso di andare solo per fargli visita. Pensai di inventarmi una scusa, un sospetto di guasto, ma mi trattenni. Tenevo d’occhio il numero dei chilometri percorsi aspettando la scadenza del tagliando, che arrivò sul finire dell’inverno. <br/>
Credetti di aver sbagliato numero quando mi rispose una voce di donna che al telefono non riconobbi, e mi disse di aspettare. Poi i rumori in sottofondo mi fecero pensare a Luisa che scendeva una scala, apriva una porta e poi un’altra. Nella pausa che ne seguì temetti che Giuliano mi rispondesse spazientito, ma non fu così. Farsi portare il cellulare in officina doveva essere una prassi usuale per lui e prendemmo accordi come d’abitudine.  <br/>
Ero ormai pratico delle strade che uscivano dalla città e non ebbi difficoltà nel seguire il percorso che mi aveva indicato: l’uscita dalla Tangenziale, dopo la galleria girare a destra, al semaforo prendere verso il monte e salire i primi tornanti, fino a un grande cancello che stava a metà di una curva. <br/>
Nel crepuscolo di marzo la strada già scura richiedeva l’uso dei fari, mentre sull’altro versante il cielo era di un pallido azzurro, sopra le cime dietro cui era sceso il sole. Mi fermai davanti al cancello chiuso. C’era un campanello e suonai, con un po’ di ansia perché l’auto sporgeva sulla carreggiata. Il cancello coperto da un telone verde e incastonato in un muro nascondeva la vista della casa. <br/>
Trascorse un certo tempo prima della comparsa di un bambinetto smunto e svelto, che spalancò il cancello senza ricambiare il mio saluto. Mi fece passare e richiuse, ma quando uscii dall’abitacolo era già sparito. Solo allora ricordai che Giuliano mi aveva parlato di una bambina. La nascita del secondo figlio, che doveva avere un paio d’anni meno della sorella, me l’aveva taciuta. <br/>
La casa era a due piani, col tetto piatto, un minuscolo terrazzo di ferro, ed era in condizioni pietose: intonaci che cadevano a pezzi, persiane scrostate, lingue scure di  umidità che risalivano la parete del piano terra. La faceva apparire ancora più squallida l’ultimo riverbero rossastro del tramonto, che la facciata sembrava aver assorbito. <br/>
Lo spiazzo in cui mi trovavo, della dimensione di un’aia colonica, somigliava a un deposito di ferrivecchi, in certe zone a una discarica. Vi notai una quantità di assi ammonticchiate e più in là una catasta di quelle che sembravano traversine di binari. Bidoni arrugginiti e carcasse di copertoni. Sacchi di plastica legati e gonfi e altri stesi a coprire oggetti informi. Telai di motorini senza ruote e una serie di biciclette fuori uso, ne contai una decina almeno. Un vecchio modello di triciclo abbandonato nel punto in cui la terra battuta confinava col prato, che conferiva al quadro un tocco patetico. <br/>
Giuliano si presentò col solito breve saluto, accompagnato da un gatto grigio e rosso. Lo aveva preceduto un altro dagli stessi colori, di cui mi accorsi quando mi si strusciò contro le caviglie. Dissi che ero venuto per il tagliando e lui annuì. Non uno sguardo, né una parola riguardo alla nuova casa, e io feci uno sbaglio dei soliti con un incoraggiante e non richiesto commento sulla sua posizione panoramica, sull’ampio terreno che la circondava. Rispose solo che c’era ancora molto da fare, nel tono che non ammetteva repliche. Il discorso in cui l’avevo visto imprigionato le volte precedenti si era di colpo esaurito. <br/>
L’accordo era che mi avrebbe riaccompagnato a casa, come succedeva al garage, e stavamo per andare quando Luisa uscì sul terrazzino per riferire di un cliente che non poteva stare al telefono e avrebbe richiamato dopo. Si era fatto quasi buio e anche questa volta stentai a riconoscerla. La luce che aveva alle spalle delineava una sagoma grossa e informe e ci misi un po’ a distinguere il pancione da gravidanza avanzata. <br/>
Giuliano sedette come d’abitudine alla guida, dopo un cenno di risposta alla moglie che voleva dire: Vado e torno. Anche fra loro due le parole erano sempre contate. <br/>
Prima di entrare a mia volta nell’abitacolo vidi che a Luisa si era affiancata la primogenita, che guardava giù con le mani strette alle sbarre di ferro arrugginito, e tornai a complimentarmi per la già bella famiglia e il nuovo bimbo in arrivo. <br/>
Rispose a monosillabi, e non so perché quella volta ci rimasi male. Mi dispiaceva che mi avesse sempre taciuto la nascita dei suoi figli, come il suo palese disinteresse quando avevo voluto riferirgli qualcosa della famiglia che mi ero a mia volta costruito. Potevo solo ripetermi che ero solo uno dei suoi numerosi clienti, e considerata la sua indole non avevo motivo di stupirmi né di prendermela se la mia vita privata gli era del tutto indifferente. Forse era più inspiegabile il motivo per cui io provavo tanto interesse per la sua. Non era solo che negli anni mi ero affezionato a lui, e non si trattava di semplice curiosità, ma piuttosto di timore e apprensione, discordanti col senso di calma e fiducia che mi aveva sempre trasmesso riguardo al proprio lavoro. <br/>
Attraversammo in silenzio la città e solo in ultimo Giuliano disse che per quella volta mi accompagnava, ma in futuro avrei dovuto arrangiarmi. Risposi che vista la distanza mi sembrava giusto. <br/>
Per andare a riprendere l’auto mi feci accompagnare da un amico, e non dovendo guidare misi meglio a fuoco il luogo. Distinsi la casa ancora da lontano, appoggiata su un breve pianoro. Una macchia biancastra che occhieggiava nella fitta vegetazione, isolata, sospesa fra la città e la montagna che aveva dietro. Quasi subito però scomparve fra il verde. Solo quel fugace scorcio la rendeva visibile.    <br/>
Dissi al mio amico di fermarsi davanti al cancello, poi dovetti aiutarlo per fare manovra. Spostava lo sguardo da un retrovisore all’altro e si lamentava della scarsa visibilità, delle auto che scendevano veloci, ripeté pià volte che su quella curva uno specchio parabolico sarebbe stato indispensabile. Mi confermava un senso di pericolo che avevo percepito anch’io, ma a cui non sapevo come sottrarmi. <br/>
Stessa ora e stessa scena, ma stavolta venne la figlia ad aprire il cancello, con appresso il più piccolo che pareva scontento di vedersi sottratta quella mansione. Non si sarebbero detti fratelli. Il maschio biondastro e smilzo come suo padre, aveva i suoi stessi occhi azzurri ma più slavati e un’aria selvatica e ribalda alla Huckleberry Finn. Lei mora e molto più in carne, sembrava voler accentuare un’espressione giudiziosa dovuta, pensai,  al suo prossimo diventare la maggiore di tre. Con un atteggiamento di rivalsa il piccolo anticipò la sorella e mi informò che papà stava arrivando, poi rientrarono alla svelta. <br/>
Nello spiazzo c’erano diverse auto, ma non la mia. Un paio con parti smontate, una senza ruote. Lo stato di abbandono pareva consolidato, senza più alcun segno di attività di recupero in corso. <br/>
La casa, con la luce accesa solo alla portafinestra del terrazzino, dava l’impressione di essere finita lì per sbaglio, come se ce l’avesse portata un ciclone. Una costa boschiva precludeva la vista della città a parte l’estrema periferia nord, con la zona dei capannoni industriali tagliata dalla ferrovia. Proprio allora passava un lungo treno merci, di cui arrivava smorzato lo sferragliare sui binari. Si alzò come in risposta il latrato di un cane, seguito da altri più distanti. A pochi chilometri dal centro città, sembrava di essere fuori dal mondo. <br/>
Mi riscosse un rumore improvviso, ed era la porta scorrevole che occupava metà del piano terra. Giuliano la spalancò dall’interno, scoprendo il vano del nuovo garage. La mia auto era lì, col cofano aperto, e Giuliano mi spiegò che doveva ancora sostituire un filtro arrivato in ritardo sul resto. <br/>
Erano comparsi anche i gatti, i due della volta prima insieme a un paio di micini che mi montavano sui piedi, e lo stesso facevano con lui, che li scacciava in malo modo. Notai subito che avevano gli occhi arrossati e ne raccolsi uno per esaminarlo meglio. Presentava i chiari sintomi di una congiuntivite avanzata, e li riscontrai anche nella gatta che con tutta probabilità era la madre.  Lo feci notare a Giuliano, lo informai che c’era il pericolo di un’infezione da germi e potevo prescrivergli una medicina adatta. <br/>
“Gli passa da sola. Le bestie si arrangiano” rispose con un’alzata di spalle, e compresi che sarebbe stato inutile insistere. <br/>
Anche all’interno faceva freddo e mi stringevo nel giaccone, mentre Giuliano vestiva la sua solita camicia a quadri. Il garage, più angusto del precedente, era stato riattato in modo rudimentale. Grandi chiazze di umidità maculavano l’intonaco e l’impiantito sconnesso, e costretto in uno spazio più ridotto il banco degli attrezzi aveva perso il suo ordine perfetto. Un grosso neon fissato al soffitto sparava una luce fredda e impietosa, che metteva a nudo tutto. <br/>
Cercai di nuovo un tono incoraggiante per domandare a Giuliano se si trovava bene nella nuova casa. Mi rispose ciò che volevo sapere in realtà. Aveva potuto risanare le fondamenta e renderla abitabile, per il resto bisognava aspettare. Dal tono sembrava la reputasse una situazione prevista e accettata, ma si intuiva uno scorno doloroso. Aggiunsi qualche parola sul terreno circostante, che si poteva prestare a vari usi. Replicò che aveva troppo da fare e se mai ci avrebbero pensato i figli più avanti. <br/>
Non riuscivo a frenare l’impulso di formulare domande insulse e inopportune, e la successiva fu se non aveva pensato di prendere un cane. Rispose che i cani davano troppo da fare, mentre con i gatti non c’erano problemi e servivano a cacciare i topi. Appena entrato avevo percepito l’odore acidulo della loro urina, e ricordai l’ansia e il disgusto con cui Giuliano mi aveva a suo tempo riferito della colonia di felini che infestava la casa della vecchia proprietaria, ma su questo almeno seppi stare zitto.   <br/>
Abbassò il cofano e fece segno che era tutto a posto. Gli chiesi il conto, preparato a dover tornare un’altra volta. Invece ce l’aveva già pronto, e mi disse come sempre in dialetto quanto gli dovevo. Poi uscì sulla strada per aiutarmi nella manovra, e fu come l’ammissione che comportava davvero qualche pericolo. <br/>


Anche dopo il trasferimento Giuliano continuò a lavorare alla concessionaria e poi in proprio, forse anche di più ora che aveva l’officina sotto casa, e i nostri rapporti non mutarono. <br/>
Avevo fatto da poco la scelta che mi prefiggevo da tempo. Sulla base della rete di contatti costruita da dipendente della clinica mi ero deciso ad aprire un ambulatorio tutto mio. In principio c’era da sgobbare parecchio, ma almeno avevo chiuso con le corvée su per le valli, ed era tempo, anche perché erano diventate due le bambine che mi aspettavano a casa, e Irene aveva dovuto ridurre al minimo il lavoro di agronoma per accudirle. <br/>
Dopo vari e infruttuosi tentativi di chiamare Giuliano al telefono, una sera mi presentai a casa sua, per la prima volta senza preavviso. Venne lui al cancello, perché era estate e lavorava all’aperto. Disse di aver smarrito il cellulare e che se non lo ritrovava ne avrebbe preso un altro. Di sua iniziativa mi fornì il numero di casa, mi sembrò un invito a servirmi di quello, e così feci sempre di lì in poi. Non seppi mai se avesse poi attivato un cellulare nuovo. <br/>
Chiamavo verso sera e rispondevano Luisa o la figlia. Spesso gli portavano il telefono in garage, doveva aver preso apposta un cordless. Col tempo appresi il nome dei figli, anche se nessuno me li aveva detti, perché mi capitava di vederli o di sentirli scambiare qualche parola fra loro. <br/>
Manuela, la primogenita, somigliava sempre alla madre e manteneva un atteggiamento composto e responsabile, si rivolgeva ai fratelli con un tono da riconosciuto vice capo. Matteo aveva il naso aquilino e i capelli color stoppa di Giuliano, e spesso veniva a guardarlo lavorare, mentre il padre si comportava come se lui non ci fosse. Vidi scorrazzare in giro anche l’ultimo arrivato, Ivo, in braghette corte e scalzo come i figli dei contadini di una volta. <br/>
Una volta che avevo urgenza di ritirare l’auto ebbi modo di vedere da vicino anche Luisa, a cui Giuliano aveva lasciato la chiave, dopo tanto che non capitava. Aveva mantenuto la figura appesantita dell’ultima gravidanza, lo stesso modo impacciato di muoversi, ma era soprattutto lo sguardo, opaco e sfuggente a rivelare un senso di fatica, con poche speranze di cambiamento. <br/>
Dopo quell'occasione l’avrei vista solo affacciata al terrazzino. Non mi fecero mai entrare in casa, e che io sappia era così con chiunque non fosse della famiglia. <br/>
In certe sere d'inverno alzavo lo sguardo alla portafinestra da cui proveniva l'unica luce, e attraverso i vetri intravedevo un profilo, il tubo di una stufa, un'ombra proiettata sul soffitto dal riflesso della televisione. La loro vita domestica mi suscitava degli interrogativi, ma mi rimase sempre ignota. Davano l’impressione di vivere in un regime di ristrettezza. I figli disponevano di un così scarso guardaroba che riconoscevo il passaggio degli abiti da uno all’altro. Lo stato di degrado dell'edificio e del circondario sembrava acquisito una volta per tutte. <br/>
A quarant’anni suonati, Giuliano aveva ancora l’aspetto di un ragazzino che cerca di mostrarsi uomo. Coi figli aveva un atteggiamento severo e distaccato, da capofamiglia di una volta, e pensai che potesse essere una sua istintiva aspirazione. L’aspetto cadente della proprietà sembrava essergli indifferente, o forse preparava in segreto qualche mossa risolutiva che avrebbe resa nota solo al momento dell’esecuzione. Mi aspettavo da lui qualche altra sorpresa. <br/>
L’officina sotto casa semplificava i rapporti, e il contatto con gli altri clienti di Giuliano divenne minimo. Una sera vidi fra le auto parcheggiate nello spiazzo la Porsche del fumatore con cui a volte chiacchieravo al vecchio garage. Aveva ancora lo sportello di fortuna, a cui si era aggiunta un’ammaccatura già un po’ arrugginita. Sapevo che a Giuliano non avrei strappato una parola sul proprietario, ma dell’auto mi disse che andavano sostituite parti del motore, e che solo a trovarle in qualche deposito di recuperi sarebbe valsa la pena di ripararla. <br/>
Qualche altra volta provai a portargli degli amici, ansiosi di servirsi dal meccanico di cui mi dicevo così soddisfatto, ma non funzionava. Giuliano non ne faceva parola, ma gli amici mi riferivano di malintesi e incomprensioni reciproche. Li ascoltavo senza commentare, senza prendere posizione riguardo alle loro rimostranze. Poi mi convinsi che Giuliano non gradiva intermediazioni e preferiva scegliersi i clienti da solo e a suo modo. <br/>
Quando non trovavo qualcuno disposto ad accompagnarmi agli appuntamenti serali portavo l’auto all’ora di pranzo, la parcheggiavo in una piazzola non lontano dal cancello e come da accordi infilavo la chiave sotto un pneumatico. Per tornare in città c’era solo il pullman che scendeva dal monte, e le corse erano poche. <br/>
Un giorno che avevo fretta mi incamminai e dopo un po’ feci l’autostop. Non mi capitava da decenni, ma non sembrava così strano su quelle strade poco trafficate, dove tutti si conoscevano. Trovai quasi subito un passaggio, e così anche in successive occasioni. Certi anziani conducenti sembravano stupiti di vedermi e a volte si fermavano per questo. Uno si rivelò un generale in pensione, e anche se non doveva andare in città mi ci portò apposta, tanta era la sua voglia di rievocare i suoi ormai lontani trascorsi. Le donne sole invece tiravano dritto con lo sguardo fisso avanti. <br/>
In seguito ottenni da Giuliano un’altra concessione, per cui se al momento del ritiro non trovavo amici disponibili, andavo col bus fino al punto più vicino e lì veniva lui a prelevarmi. Una sera in cui era troppo occupato per muoversi mandò al suo posto la figlia, patentata da poco, che restò silenziosa per tutto il tragitto, se non quando volle giustificare il tortuoso percorso di strade secondarie che seguiva  per rientrare. Preferiva evitare la galleria, che le faceva paura, e lo disse con noncuranza, come se fosse una reazione del tutto ragionevole. <br/>
A me invece continuava a mettere paura la curva su cui si affacciava il cancello, soprattutto di giorno, perché col buio le auto erano annunciate dalla luce dei fari. Per miracolo e solo grazie al mio stato di allerta un giorno evitai di essere investito da un pazzo che scendeva a tutta. Ogni volta che dovevo fare quella manovra rimpiangevo il vecchio garage, la quiete in cui era immerso e sapeva trasmettermi. <br/>  
Alla famiglia di Giuliano si aggiunse un quarto figlio e lui come sempre non me ne informò, lo vidi per caso tenuto in braccio dalla primogenita. Un altro maschio, non ho mai saputo il suo nome. <br/>
Anche a noi sarebbe piaciuto averne degli altri, ma eravamo già abbastanza impegnati con le due esuberanti adolescenti che ci ribaltavano la casa. Il mio ambulatorio lavorava bene e ora vi collaborava un giovane e affidabile tirocinante, mentre Irene era tornata a svolgere a pieno il proprio lavoro come desiderava. <br/>
Ci eravamo un po’ sistemati, potevamo finalmente tirare il fiato e ogni tanto prenderci una vacanza. Il resto era dato per acquisito, e così anche il mio rapporto con Giuliano. Fino al momento in cui il suo comportamento tornò a sorprendermi. <br/>
Avevo da poco acquistato un’auto sempre usata ma a chilometri zero, che mi sembrava un lusso in confronto a quelle precedenti. Una sera che gliela portai, anche se dovevo solo lasciargli la chiave attaccò a parlare a ruota libera come nell’unica altra occasione in cui era successo. <br/>
Ce l'aveva con la concessionaria dove da qualche tempo al vecchio titolare si era affiancato il figlio, odioso e stupido. Ripeteva sempre la parola “ottimizzare”, che voleva dire guadagnare di più riducendo la qualità del lavoro, dei ricambi. Ma i clienti non erano stupidi e più d'uno si era già rivolto altrove. Lui cercava di fare le cose come si deve, ma quello lo boicottava, lo criticava davanti a tutti ed era la cosa peggiore. Guai se il capo officina perde la fiducia e il rispetto degli altri dipendenti. 
Era una sera d’inverno e battevo i piedi dal freddo come ai tempi del garage, ma lui insisteva nel raccontare episodi che avvaloravano le sue ragioni, ritornava su quelli che giudicava più incresciosi, con lo stesso eloquio concitato e irrefrenabile che a suo tempo gli aveva scatenato l’acquisto della casa, ma in tono ben diverso. <br/>
Al momento del ritiro dell’auto lo trovai ancora prigioniero del suo monologo. Ripeteva le stesse frasi e nel medesimo ordine, col ritmo ossessivo dell'animale che cammina avanti e indietro nella gabbia. Ed era sempre più rabbioso, convinto di essere sottoposto a un’ingiustizia e un’umiliazione inaccettabili. <br/>
Mi fece di nuovo sentire in pena per lui, e per questo tornai a poche settimane di distanza, per la sostituzione di una lampadina che avrei potuto fare più comodamente altrove. E questa volta a sorprendermi fu il fatto che mi riuscì di cavargli a malapena qualche parola. Di quella bufera interiore era sparita di colpo ogni traccia. <br/>
Quando gli domandai delle questioni che tanto lo affliggevano rispose che aveva cambiato posto di lavoro. Era stato assunto sempre come capo officina in una diversa concessionaria, e il suo tono poteva far pensare che pur a denti stretti si fosse preso una specie di rivincita. Ma era solo il primo di una serie di spostamenti, che seguirono a distanza sempre più ravvicinata. <br/>
Qualche mese più tardi passò a un'altra officina, questa volta come semplice dipendente. Lo disse con noncuranza, e mi sforzai di credere che potesse essergli più congeniale ridurre oneri e responsabilità, fare il suo e basta. Il passo successivo fu però una ditta che riparava mezzi agricoli, e il distacco con cui Giuliano me ne informò mi sembrò per la prima volta ostentato e doloroso. Mi sorprendeva già il fatto che anche se non gli veniva richiesto mi mettesse al corrente di questi sviluppi, come per cercare di prenderne atto, di rendersene conto. <br/>
Intanto i suoi modi si facevano ancora più scostanti. Una sera ebbe un violento alterco con un cliente, e non era da lui soprattutto non curarsi che io vi assistessi. Avevo l’impressione che non si trattasse di un episodio isolato, che diversi clienti, specie quelli di recente acquisizione, gli voltassero le spalle. Nel piazzale c’erano sempre meno auto, fra le quali spiccava la vecchia Porsche, in condizioni che facevano pensare a un destino ormai segnato <br/>
La situazione gli era sfuggita di mano e non sapeva farsene una ragione. Non poteva accettare di vedere smentita la validità delle sue scelte, di tutto il sistema di vita che con tanta determinazione aveva costruito per sé e la propria famiglia. <br/>
Questo almeno leggevo nel suo sguardo più risentito che mai, nei gesti scomposti e distratti con cui lavorava, e ancora di più nello sconforto di certe frasi che si lasciava sfuggire. Sembrava consumarsi nel corpo e nello spirito. Una volta tentai di parlargliene e come era prevedibile non mi permise di farlo. <br/>
Quella sera mi confidai con Irene. Eravamo già a letto e prima di metterci a dormire aspettavamo senza dircelo il rientro delle ragazze che sarebbero rimaste fuori fino a tardi. Già altre volte l’avevo messa a parte delle vicende di Giuliano, e diceva che le sembrava di conoscerlo anche senza averlo mai visto. <br/>
“Prova di nuovo a parlargli” mi suggerì. “Forse ha bisogno di aiuto ma è troppo orgoglioso per ammetterlo. Siete amici da tanto tempo”. <br/>
Obiettai che non sapevo se potevamo dirci proprio amici, e poi rimasi a pensarci a lungo. Decisi di darle ascolto e alla prima occasione feci un nuovo tentativo, al quale Giuliano si sottrasse in modo ancora più brusco della volta precedente.  <br/>
Speravo solo che potesse ricorrere a qualche estrema risorsa, con la forza di carattere e l’ostinazione che aveva più volte dimostrato. Invece il peggio doveva ancora venire, più rapido e crudele dell’immaginabile. <br/>
Una sera che mi presentai per il ritiro dell’auto trovai Matteo, il secondogenito, che era diventato un robusto ventenne e mi accolse col modo di fare ispido e lo sguardo sfuggente tipici del padre. Stava lavorando a uno scooter, mentre il piazzale era ingombro di auto malandate, coi finestrini aperti, fra le quali si era insediata una nutrita colonia di gatti. Andò a prendermi la chiave e disse che Giuliano era costretto a letto con la febbre, ma da come lasciò cadere la mia successiva domanda pensai si trattasse di un malanno passeggero. <br/>
Perciò mi si gelò il sangue nelle vene pochi mesi dopo, quando alla riconsegna dell’auto che mi aveva portato alla revisione, senza cambiare tono di voce ma in dialetto Giuliano disse che aveva un cancro. Una forma subdola, e sottolineò quell’aggettivo che doveva aver sentito usare dai medici, mentre si toccava col dito un punto del fianco. <br/>
Nel consueto tono distaccato vibrava una nota di sgomento, e più di tutto fu quella ad atterrirmi. Provai a dire qualcosa che andasse oltre le frasi di circostanza. Mi gettai in un concitato discorso sui continui progressi della chirurgia e sull'imprevedibilità dei decorsi. Portai come esempio e illustrai per esteso quello a lieto fine toccato a un mio conoscente, con ampio ricorso alla terminologia medica per risultare più convincente. <br/>
Lui non fece commenti, e anche il breve sguardo che mi rivolse non rivelava alcuna emozione. Estremo pudore, virile accettazione o connaturato fatalismo: anche in quella tragedia la sua impassibilità restava un insondabile mistero. <br/>
Volle solo precisare che a parte qualche giorno in cui doveva sottoporsi a degli esami avrebbe continuato come sempre il lavoro, e nel frattempo notavo che Matteo si muoveva più disinvolto per il garage, senza mai perdere di vista il padre e i gesti con cui richiedeva il suo intervento quando ne aveva bisogno.
Poi Giuliano mi accompagnò al cancello, ed ebbe una breve esitazione prima di richiuderlo. Mi è rimasta bene impressa quell'immagine, in cui non so se vidi i primi segni della malattia, nei capelli diradati sopra la fronte, nell’accentuato incavo degli occhi, meno azzurri e luminosi di un tempo, o piuttosto la sua vera età. Senza perdere la sua aria da ragazzino scontroso, Giuliano era diventato un uomo di cinquant’anni. Solo allora me ne rendevo conto, e anche il pensiero che io ero prossimo ai sessanta non mi aveva mai fatto tanta impressione. <br/> <br/>


Fui tentato più volte di ripassare per sapere come stava e sempre rimandavo, mi dicevo che non avrebbe gradito, e potevo reputarlo un alibi plausibile. Finché mi decisi a chiamarlo al telefono. <br/>
Mi rispose la figlia, che senza preamboli dichiarò “Il papà è morto ieri notte. Il funerale sarà dopodomani alle undici. Però mio fratello Matteo continua il lavoro che faceva il papà prima”. Un annuncio calibrato e privo di tono, che doveva aver messo a punto in svariate repliche <br/>
Con un nodo alla gola riuscii a dire che mi dispiaceva, che era una disgrazia terribile. Lei restò zitta e non mi rimase che congedarmi con uno stentato saluto. 
Dietro la freddezza che è la mia più immediata reazione alla morte di una persona cara, di cui mi sento ogni volta colpevole, provavo una grande pena e avrei voluto fare qualcosa per loro. Ma il sostegno concreto che avrei potuto dargli era uno solo, e glielo negai. Non portai la mia auto da Matteo, per sfiducia in un meccanico alle prime armi, o perché mi avrebbe dato troppa tristezza tornare in quel luogo. Così quando col tempo mi resi davvero conto che Giuliano non c’era più, e di ciò che aveva rappresentato per me, al dolore della perdita si legò un senso di inadempienza, di mia ingratitudine nei suoi confronti. <br/>
Qualche anno più tardi mi sarei dato una parziale assoluzione in seguito all’incontro con un meccanico altrettanto affidabile e amabilissimo. Avrei ripensato alla mia più che trentennale fedeltà nei confronti di Giuliano, mantenuta a dispetto di un rapporto che spesso si rivelava ostico e disagevole, e stabilito che non avevo avuto ragione di sentirmi in debito con lui. Ogni tanto mi capita ancora di ripetermelo. <br/> <br/> 


Al funerale c’era meno gente di quanto mi aspettassi, riempiva a malapena le prime file dei banchi nella cappella dove fu celebrata una stringata funzione religiosa, nello stile di Giuliano. <br/>
All’uscita mi misi in fila insieme agli altri davanti alla moglie e ai quattro figli per le condoglianze di rito. Luisa era infagottata in un cappotto scuro che accentuava il pallore del suo viso. Coi capelli scompigliati dal vento, si guardava attorno smarrita, come se aspettasse ancora l’arrivo di qualcuno. I ragazzi stringevano la mano a tutti, a occhi bassi, irriconoscibili negli abiti da cerimonia rimediati chissà dove. <br/>
Riconobbi dei clienti di Giuliano e perfino qualcuno che non incontravo dai tempi del vecchio garage, ma non vidi il tipo della Porsche, l’unico con cui avrei scambiato volentieri qualche parola. Il prete recitò un’ultima preghiera prima della tumulazione della salma, e dovetti distogliere lo sguardo per frenare il pianto, alla vista dei figli di Giuliano che restavano impassibili. Poi il freddo che stagnava sotto il portico dei tombini disperse in fretta la piccola folla silenziosa. <br/>
Per il successivo tagliando mi rivolsi all’officina consigliata da un amico, dove mi accolse un ragazzo che aveva gli occhi azzurri e la stessa età di Giuliano quando ci eravamo conosciuti. <br/>
Il ragazzo squadrò la mia auto e volle sapere quali interventi erano stati fatti in precedenza. Risposi che lo ignoravo, perché il meccanico di prima non me ne dava conto, ma che non capivo dove stesse il problema: il tagliando non era sempre lo stesso? Accennò a controlli programmati e periodiche sostituzioni, per cui si rendeva necessario disporre di alcuni dati. Sembrava piuttosto stupito. Poi alzò il cofano e sorrise, soddisfatto di aver trovato quello che cercava. 
Mi avvicinai e restai a lungo a contemplare le note scritte con un pennarello verde smeraldo, sul carter che copre il filtro dell’aria. Vi erano riportati data e chilometraggio dell’ultimo tagliando, poi anche di altre sostituzioni di rilievo: frizione, cinghia di distribuzione, pastiglie dei freni. <br/>
Dopo essere stato tanto a lungo il solo depositario di tutto ciò che riguardava le mie auto, per la prima volta e a mia insaputa Giuliano aveva lasciato una traccia scritta per lo sconosciuto successore a cui passava il testimone. <br/>
           
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
        </div>
    </div>
</div>


    )
  }
}

import React, { Component } from 'react'

export default class OnlineWorksCielo extends Component {
	render() {
		return (
			<div class="modal" id="onlineworkscielo" tabindex="-1" role="dialog" aria-hidden="true">
				<div class="modal-dialog modal-full" role="document">
					<div class="modal-content">
					<div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
            </div>
						<div class="modal-header">
							<h5 class="modal-title">Il cielo che prima non c’era - Alessandro Tamburini</h5>
						</div>
						<div class="modal-body-testionline p-4" id="result">


							“Ti dico che le cose cambieranno! Hanno già cominciato a cambiare” disse Riccardo, fermandosi per dare più peso alle parole. Toni si fermò a sua volta, con la testa incassata fra le spalle e le mani affondate nelle tasche dei pantaloni. <br />
							“Io non ne sarei così sicuro” ribadì, e con la lingua si fece girare fra i denti un filo d’erba che si era messo in bocca poco prima. <br />
							“Pensa solo che non c’è più il fascismo! Ti sembra poco?” insistette Riccardo alzando il tono di voce. <br />
							“Veramente c’è ancora la Repubblica di Salò, e abbiamo i tedeschi in casa…” obbiettò Toni. <br />
							“Arriveranno gli angloamericani, la guerra l’hanno già vinta, è solo questione di tempo…”. <br />
							“Sì, ma noi l’abbiamo persa” osservò Toni, e lui si infervorò del tutto, disse “La guerra sarà presto finita, è questo che conta! E tutto potrà ricominciare! Non capisci che cosa significa!?” <br />
							Toni riprese a camminare e Riccardo gli mollò un gran pugno sulla spalla, in cui scaricò tutto il proprio disappunto. <br />
							“Testone di un menagramo che non sei altro!” gli disse, e l’altro accusò il colpo, si massaggiò il muscolo del braccio indolenzito. <br />
							Riccardo aveva a volte di quei moti di insofferenza nei confronti dell’amico. Toni era un ragazzo sveglio, gli piaceva parlare con lui proprio perché aveva sempre un modo suo di guardare le cose, diverso da quello della maggioranza. Però aveva la tendenza a vedere sempre il lato scuro, l’inganno, la fregatura, il verme che bucava anche la mela più bella. E quando prendeva una posizione non c’era più verso di smuoverlo di un millimetro. <br />
							Si conoscevano da sempre, da quando portavano i pantaloni corti anche in inverno e condividevano i giochi dell’Oratorio di San Pietro: interminabili partite di pallone d’estate, sul campetto polveroso, e d’inverno calciobalilla, bilie e figurine, con don Ettore che con grandi ceste di brioches li attirava verso la Funzione e combatteva la quotidiana battaglia di dare loro almeno un’infarinatura di religione. A dodici anni avevano avuto in regalo tutti e due una bicicletta ed era stato in quelle lunghe pedalate e sudate che era maturata fra loro un’amicizia esclusiva, che non sarebbe più venuta meno. Poi si erano iscritti allo stesso liceo ed erano cominciate le uscite con le ragazze, con molti impacci e titubanze, seguite da ore di risate e di grande complicità fra loro due, a commentare e riepilogare le prime agognate e per lo più mancate conquiste. Già allora Toni manifestava la sua vena critica, smontando senza pietà i suoi furori romantici, e quel che è peggio più di una volta era capitato che le ragazze preferissero lui, che pure non si innamorava mai di nessuna. <br />
							Riccardo si fermò nel punto in cui il sentiero che avevano percorso si affacciava sul primo slargo asfaltato, alla periferia della città. Rivolse un lungo sguardo ai tetti delle case, col rosso acceso dalla viva luce del mattino, poi prese dalla tasca il tabacco e si arrotolò una sigaretta. Toni fece segno che ne voleva anche lui e Riccardo gli porse il tabacco, mentre accendeva sfregando il fiammifero su un muretto. Poi gli accese la sigaretta con la sua, per non sprecare un secondo fiammifero che era una merce preziosa e diedero insieme le prime avide boccate.
				Era già da un po’ che avevano quel vizio, che risparmiavano su tutto per raggranellare i soldi necessari a mantenerlo, ma non si azzardavano ancora ad accendere la sigaretta in casa, in presenza dei genitori. Una volta che il padre di Toni lo aveva sorpreso per strada con una in bocca gliel’aveva fatta volare via con uno scappellotto che aveva lasciato il figlio tramortito per mezz’ora. <br />
							Ma a diciassette anni compiuti erano e si sentivano grandi, specie quando si trovavano da soli, quando si sentivano la propria vita tutta fra le mani. <br />
							Si erano dati appuntamento quella mattina di domenica, all’ora in cui un tempo andavano a messa, per una delle loro passeggiate fuori città, lungo il sentiero che costeggiava il fiume. Si erano fermati come al solito su un pontile di legno che si staccava dalla riva, nel punto in cui il fiume formava una specie di insenatura, a fumare e a chiacchierare. Prima avevano parlato di ragazze, anche se in quel momento non avevano niente di importante per le mani, ma sulla strada del ritorno i discorsi si erano portati sugli avvenimenti degli ultimi mesi, con le infinite congetture su quando sarebbe finita la guerra e cosa sarebbe accaduto dopo. <br />
							“Io non credo che finirà tanto presto…” disse Toni, come se nella lunga pausa non si fosse mai staccato da quei pensieri. “L’Italia è lunga e i tedeschi stanno rendendo la vita dura agli Alleati. Dovrà passare almeno un altro inverno, stanne pur certo”. <br />
							Lui fece il gesto di cacciare via quel tempo come un insetto fastidioso. Disse “Se deve venire un altro inverno, passerà anche quello. Il momento arriverà, e poi niente sarà più come prima!”. <br />
							Toni scosse la testa. “Cosa ti spetti che succeda? Saranno sempre loro a comandare…” <br />
							“Loro chi?” <br />
							“Quelli che hanno comandato sempre, con o senza fascismo, in guerra e in pace: i padroni delle industrie, delle banche, quelli che oggi hanno sulla tavola il pane bianco, la frutta, e sigarette buone a volontà…”. <br />
							“Non cambierai mai, devi sempre vedere il lato peggiore” sbottò Riccardo. “La guerra finita è un sogno e solo tu non riesci a vederlo. Io dico che faremo festa per un anno intero!”. <br />
							“Metterà fine a tante sofferenze, questo lo so bene” ammise Toni “ma sarà solo l’inizio, con tutto quel che bisognerà ancora fare dopo”. <br />
							Erano arrivati all’incrocio che portava in Centro, al caffè dove erano soliti riunirsi la domenica a quell’ora. <br />
							“La politica verrà quando sarà il suo momento” rispose lui. “Adesso pensiamo a mandare via i tedeschi e poi…”. <br />
							Gli si spezzarono le parole in bocca perché partì proprio in quel momento l’urlo della sirena, vicinissima visto che si trovavano a pochi isolati dal Comune. <br />
							Subito i passanti che avevano attorno accelerarono il passo, qualcuno spiccava già la corsa e anche loro cominciarono a muoversi, pur senza ancora dirsi per dove. Toni scrutava il cielo guardando verso sud, mentre con un gesto istintivo lui ritirava il collo fra le spalle, come per ripararsi già dalle bombe. Disse “Da qualche giorno sembrava che ci volessero lasciare in pace”. <br />
							“Sembrava…” rimarcò Toni, che subito puntò il braccio in direzione delle montagne e un istante dopo nell’azzurro terso del cielo balenò il luccichio di una carlinga, mentre si cominciava a sentire il rombo sordo dei motori. <br />
							Adesso tutti correvano, in un vocio di grida e richiami che rimbalzavano tra le finestre e la strada. Persiane sbattevano e venivano chiuse, a due passi da loro cadde con gran fragore la serranda di un caffè e li fece sobbalzare come se si trattasse della prima bomba. Si misero a correre anche loro, ancora senza una direzione precisa, quasi saltellando e continuando a guardarsi per prendere in fretta una decisione. <br />
							“I miei a quest’ora sono tutti a casa e in due minuti arrivano al Rifugio dell’ospedale!” disse Riccardo, col fiatone dell’ansia, più che della corsa. <br />
							“Idem per i miei” fece l’altro “ma noi non ce la facciamo ad arrivare fin là. E’ molto più vicino il Rifugio di San Martino”. <br />
							“Allora andiamo lì” disse lui, spiccando per primo una corsa vera e decisa, e subito si voltò per controllare che l’amico gli stesse dietro. <br />
							Il rombo degli aerei ingigantiva sempre più, sembrava dovesse far precipitare il cielo e Riccardo correva avanti a testa bassa. Sapeva che c’era poco tempo, pochi minuti, forse solo qualche manciata di secondi. Sapeva che i velivoli andavano molto più veloci di lui. <br />
							Altri correvano dietro e avanti a loro, quasi tutti nella medesima direzione perché il Rifugio di San Martino era meta comune. Lui si disse che sarebbero arrivati in tempo, che ce l’avrebbero fatta come altre volte, anche se ogni volta poteva essere quella che buttava giù la sua casa, o che faceva vittime fra le persone care. Bisognava non pensarci. Bisognava solo correre e sperare che il male fosse il più piccolo e il più lontano possibile. <br />
							Correvano e divoravano isolati, il Rifugio era ormai a poche centinaia di metri e a Riccardo sembrò di averne già nelle narici il tipico odore di muffa e di terra umida. Ma mentre giravano l’angolo della farmacia di colpo Toni si fermò e lui dovette fare lo stesso, portando una mano alla milza che gli faceva male. <br />
							“Dai che manca poco” disse, ma Toni non si era fermato per la stanchezza, gli era venuta in mente di colpo una cosa importante e la disse. “Silvestro è a casa con la caviglia rotta. Lui e sua madre potrebbero aver bisogno!”. <br />
							Riccardo tirò subito indietro, disse “E cosa vuoi che facciamo noi? Mica possiamo portarlo di peso fino al Rifugio!”. <br />
							Toni continuava a guardare lui ma aveva cominciato ad arretrare, come se avesse già deciso. <br />
							“E’ meglio andare a vedere” ribadì. <br />
							“Lo sai che vanno sempre in cantina! Si arrangeranno così anche stavolta!” provò a insistere lui, ma come c’era da aspettarsi Toni non intendeva recedere dal suo proposito, disse “Siamo lì in due minuti. Poi se mai scendiamo in cantina con loro”. <br />
							“Due minuti un accidente” ribatté lui, mentre gli saliva dal petto un ansimare più forte di quando stava correndo. “Arrivano prima le bombe! Ti vuoi fare ammazzare! Dammi retta, andiamo al Rifugio!”. <br />
							“Non importa, vado io solo. Ci ritroviamo quando sarà finito tutto” concluse l’altro, e Riccardo fu attraversato da una specie di vertigine, in cui si mescolavano ansia e orgoglio, fedeltà e paura. Odiava Silvestro e la caviglia che si era rotta stupidamente un paio di settimane prima, e cercava di convincersi che non aveva bisogno del loro aiuto. Una voce gli diceva di non separarsi dall’amico, un’altra di riprendere la corsa verso il Rifugio e lui era come paralizzato, incapace di darsi il comando di scattare nell’una come nell’altra direzione. Ma la voce più forte fu quella del primo lungo fischio, di poco seguito da un boato che esplose ancora distante, ma mandò in pezzi i vetri delle case vicine. E come se avesse troncato di netto la gomena di un ormeggio, mentre Toni ancora esitava come per dargli un’ultima chance di seguirlo, Riccardo si lanciò a testa bassa in direzione del Rifugio, lungo la strada ormai quasi deserta, correndo a zig zag, dal riparo di un edificio a un altro, come se questo bastasse a evitare le bombe che da un momento all’altro potevano piovergli sulla testa. <br />
							Alzò gli occhi per un istante e vide due aerei poco lontani, più bassi delle montagne, con i portelloni spalancati e i grappoli di bombe che roteando precipitavano giù. Respirava come un mantice e l’aria gli bruciava dentro dalla gola ai polmoni. Finché vide da lontano il Rifugio e proprio allora scoppiò sulla città l’inferno di fischi e boati, e a ognuno seguiva un frastuono di crolli e di vetri frantumati che piovevano sulla strada. In quel putiferio gli sembrò di non sentire più niente, e coprì l’ultimo tratto come un tuffo disperato. <br />
							Un boato violento esplose vicinissimo e fece tremare la terra. Lui incespicò, solo per miracolo riuscì a tenersi in piedi e c’era quasi, vide la tozza volta che faceva da accesso al Rifugio e ci si tuffò dentro, quasi rotolò giù per i consunti gradini di pietra fino al portone massiccio che qualcuno aveva appena richiuso, ci si buttò contro prendendolo a pugni e il portone si riaprì, fu tirato dentro da dieci mani, finì accasciato per terra nel tramestio degli ultimi arrivati come lui, ancora assiepati nell’angusto ingresso. <br />
							Per qualche istante rimase come tramortito, col cuore che gli batteva dappertutto e il sudore che gli colava a fiotti lungo la schiena. Poi tornò a essere presente a se stesso e subito si ritrovò davanti Toni e il momento in cui si era allontanato precipitosamente da lui. <br />
							Era un verdetto senza appello. Era scappato, ci voleva almeno il coraggio di ammetterlo. Forse Silvestro aveva davvero bisogno del loro aiuto e comunque non doveva separarsi da Toni. Era stata la paura a paralizzargli il pensiero e a piegarlo al suo volere. <br />
							Intanto cominciava a guardarsi intorno, in quel Rifugio che non conosceva. Ci era entrato solo una volta, per un inconsueto allarme pomeridiano che lo aveva sorpreso da solo da quelle parti. Era più grande di quello dell’ospedale, aveva i soffitti più alti, ma c’era lo stesso puzzo di marcio, la stessa aria umida, bagnata quasi, che adesso gli raffreddava il sudore negli abiti appiccicati. Si alzò in piedi e si fece largo fra quelli che si erano fermati nell’ingresso, e tremavano e si stringevano gli uni agli altri quando si udiva un fischio sibilare non lontano dal Rifugio e poi il boato della bomba che rintronava cupo e faceva scricchiolare le travi che puntellavano la volta. <br />
							Aspettò un momento di calma ed entrò nell’ambiente più grande, una specie di galleria lunga quasi cento metri, con due file di panche lungo i bordi. Era piena di gente, gli uomini in piedi, i bambini sulle ginocchia delle madri, le vecchie col rosario stretto fra le dita e le labbra piegate in un’incessante giaculatoria. Cominciò a risalirla e intanto frugava con lo sguardo le persone per vedere se c’era qualcuno che conosceva. Al Rifugio dell’ospedale si ritrovava sempre fra amici e parenti e lì invece non vedeva che estranei, mentre in quel momento avrebbe scambiato volentieri due parole con qualcuno. <br />
							Non si perdonava il proprio atto di viltà e non sopportava l’idea che potesse essere successo qualcosa a Toni, perché allora sì la sua colpa sarebbe diventata un peso schiacciante. <br />
							Si spinse ancora avanti, solcando quella folla che come un unico corpo si contraeva e tratteneva il fiato quando risuonava il fischio di una bomba e dopo che era caduta riprendeva a respirare e ogni volta si sollevava un mormorio di sollievo e di commenti a bassa voce. Aveva anche lui paura delle bombe ma come le altre volte si sentiva abbastanza sicuro al Rifugio, anche quando le pareti tremavano e piovevano polvere e calcinacci dal soffitto. <br />
							Arrivò ad affacciarsi sulle camerate che si aprivano ai lati nella galleria come cappelle di una chiesa, e dentro c’erano panche e  materassi gettati sull’impiantito di legno e anche lì tanta gente seduta in fila, stretti come sul tram, e tutti che lo fissavano quando metteva la testa dentro per individuare qualche conoscente. <br />
							Finalmente, tornato nella galleria centrale, vide di spalle una testa riccioluta su una figura che gli sembrò di riconoscere e in effetti era proprio lui. <br />
							“Ciao Carlo, sei qui con tuo fratello?” gli disse affiancandolo. <br />
							“No, ero da mia zia quando è suonato l’allarme e sono venuto qui con lei, ma adesso l’ho lasciata con le sue amiche” rispose quello, e gli mollò una pacca sulla spalla per mostrare che era contento di vederlo. “Tu invece sei fuori zona” soggiunse, e lui si limitò ad annuire perché in realtà si stava domandando se non poteva raccontare a Carlo della faccenda di Toni. Si rispose di no, perché se ne vergognava e non era abbastanza in confidenza con lui. <br />
							Carlo era stato un suo compagno di scuola alle Medie ma avevano continuato a vedersi, anche insieme a suo fratello maggiore, che più di una volta aveva fatto gruppo con loro ed era simpatico, non si dava delle arie come facevano sempre quelli più grandi. Si sapeva che il loro padre aveva avuto dei problemi coi fascisti, ancora prima della guerra, e che erano andati a prenderlo a casa in piena notte. Riccardo si sentiva dire da sua madre che non doveva frequentarli perché erano teste calde, ma lui non le dava retta. Gli piacevano i ragazzi con cui si trovava bene a parlare e dopo Toni loro erano i migliori. <br />
							Fischiò vicina una bomba e vide che tanti si prendevano la testa fra le mani in attesa del boato, che esplose e si rifranse come un tuono fra le pareti. Poi si sentirono le raffiche intermittenti della contraerea, <br />
							Carlo lo tirò per un braccio, gli fece segno di sedere con lui in un angolo sgombro, fra due panche accostate alla parete. Ma non c’era abbastanza posto per entrambi e lui sedette sui talloni, a un palmo da una bambina minuscola, che con una mano si teneva aggrappata alla gonna della madre e nell’altra stringeva una bambola di pezza. Le sorrise e lei fece dondolare la bambola, ma senza mutare la sua espressione seria. <br />
							“Bombardano sempre più spesso. Vuol dire che si preparano all’offensiva” disse Carlo, e lui annuì, disse “L’altra notte Pippo ha tirato sulla centrale elettrica e ha colpito anche la casa dei Rensi, che abitano lì vicino”. Cominciarono a scambiarsi notizie di amici e conoscenti e dei molti che erano sfollati, dopo il pesante bombardamento di un mese prima. <br />
							“Lo sfollamento è un bene anche per chi resta” disse Carlo. “Prima qui si riempiva il doppio di adesso, si stava schiacciati come sardine”. Parlando ingannavano l’ansia e l’aria irrespirabile del Rifugio. Si zittivano come tutti e trattenevano il respiro quando partiva un nuovo fischio. <br />
							Lui era contendo di aver trovato qualcuno con cui conversare. Rispondeva a tono ma era sempre concentrato su quando sarebbe finita e  avrebbe potuto uscire fuori, rivedere Toni sano e salvo e allora non gli sarebbe importato più della propria ingloriosa fuga, era disposto anche a farsi prendere in giro e ad ammettere: sì, quando ho sentito fischiare la prima bomba mi ha preso la fifa, e avrebbero riso e tutto sarebbe tornato com’era. Era quello che desiderava con tutto se stesso. <br />
							Un fischio più acuto e prolungato riempì di paura gli occhi delle persone che avevano attorno e fece alzare di tono la preghiera che una vecchia recitava col rosario fra le dita. <br />
							“Vogliono tagliare i rifornimenti ai tedeschi” disse Carlo alzando gli occhi al soffitto, “poi avanzeranno, e allora dovremo fare qualcosa anche noi”. <br />
							Lui pensava ancora a Toni e per una volta gli venne da rispondere come avrebbe fatto l’amico. <br />
							“Verranno a liberarci, ma intanto ci buttano le bombe sulla testa” disse. “Ci ammazzano e alla fine dovremo anche ringraziarli”. <br />
							Carlo replicò con un’espressione risoluta, poi abbassando la voce disse “Se mi arriva la cartolina per arruolarmi con la Repubblica io non mi presento, vado in montagna e rimango lassù”. <br />
							“Sono da poco arrivate le cartoline per le classi ’24 e ’25” ricordò Riccardo. “I prossimi siamo noi…”. <br />
							Carlo piegò il capo verso di lui e abbassando ancora la voce mormorò “L’ha avuta anche mio fratello. E non si è presentato. È andato coi partigiani. E’ via già da cinque giorni”. <br />
							Quelle parole ebbero un effetto potente su di lui. Non si aspettava che le cose fossero già a quel punto, che fosse così vicino il momento delle decisioni. <br />
							“Coi partigiani…” ripeté. “E come ha fatto?”. <br />
							“E’ salito al paese dove hanno la casa i miei nonni. Gli hanno detto che lì avrebbe potuto mettersi in contatto. E infatti non è più tornato. Aspettiamo notizie. Mia madre è terrorizzata ma io dico che ha fatto bene”. <br />
							Già da qualche mese si sentiva parlare dei partigiani. Molti di quelli tornati a casa dopo l’8 settembre si erano rifugiati in montagna per non farsi prendere dai tedeschi, ma nei primi tempi si erano limitati a nascondersi e a sfuggire i rastrellamenti. Poi erano arrivate le notizie delle prime azioni: un deposito di carburante fatto saltare in aria, una spedizione punitiva contro una spia e appena una settimana prima si era saputo di un’azione nella provincia vicina, un’imboscata tesa a una camionetta tedesca, in cui era stato ferito gravemente un ufficiale della Wermarcht. <br />Ma per Riccardo si trattava ancora di vicende lontane e fantasiose e non riusciva a immaginare di potersi davvero unire a loro, lui che non aveva mai preso in mano un’arma, e che non aveva ancora dormito una notte lontano da casa. Ne aveva parlato anche con Toni, e non aveva le idee molto chiare nemmeno lui. Però avevano concordato che indossare la divisa e andare a combattere di nuovo per il Duce non era giusto, anzi non era nemmeno da pensarci. Questo voleva dire che di lì a poco, mesi o forse meno ancora, qualcosa di ancora inconcepibile in un modo o nell’altro sarebbe accaduto. <br />
							“Io non ho mai tenuto in mano un’arma in vita mia” mormorò per palesare solo uno dei tanti dubbi che aveva in mente, e pensò che per unirsi ai partigiani avrebbe dovuto migliorare, perché non avrebbero saputo cosa farsene di uno che al primo botto se la dava a gambe. <br />
							“Te lo insegnano loro” disse Carlo. “Hanno quelle prese ai nemici nelle azioni. Poi ho sentito dire che gli Alleati fanno dei lanci dagli aerei e mandano giù viveri, armi, tutto quello di cui c’è bisogno”. <br />
							Da qualche minuto erano finiti i fischi e gli scoppi e la gente cominciava a rumoreggiare e a scalpitare per uscire. Finalmente suonò la sirena del cessato allarme e la porta del Rifugio cominciò a risucchiare fuori quella piccola folla di persone ammassate. Lui e Carlo impiegarono parecchio a raggiungere l’uscita, e finalmente ritrovarono la luce del sole, che piegò loro lo sguardo dopo la penombra del Rifugio. C’era uno strano silenzio nell’aria, in fondo al quale finiva di spegnersi il fragore sordo dei velivoli che se ne tornavano da dove erano venuti. <br />
							La gente defluiva ansiosa, accelerando subito il passo verso le proprie case per vedere in che stato erano ridotte, per ricongiungersi ai familiari da cui erano separati al momento dell’allarme, e loro fecero lo stesso. Camminavano su tappeti di vetri rotti ma a lui sembrava di non vedere grossi cambiamenti, anche se non era facile distinguere fra i danni dei precedenti bombardamenti e quelli dell’ultimo. E quasi subito gli si aprì il cuore nel vedere ancora da lontano il suo amico Toni, inconfondibile con le sue gambe magre e lunghe e il suo viso affilato che fendeva l’aria. Veniva avanti a grandi passi verso il Rifugio e appena lo vide il suo viso si rischiarò, gli corse incontro. Fu un abbraccio interminabile, accompagnato da suoni gutturali che non riuscivano a prendere la forma di parole. <br />
							“Avevi ragione” disse poi Toni. “Silvestro era già in cantina e sono rimasto con lui e sua madre. A un certo momento i boati erano così forti che pareva la casa dovesse caderci sulla testa!” <br />
							“Quali quartieri sono stati colpiti?” gli domandò ansiosamente lui, e Toni disse “Ho sentito che hanno preso di mira la stazione ma non sono ancora andato da quella parte. Prima volevo vedere la tua brutta faccia tutta intera”, e lui si sentì di colpo liberato da un peso. Forse non era stato grave come gli era sembrato, oppure Toni lo aveva perdonato e basta, non aveva importanza. Non glielo aveva fatto pesare e glie ne era immensamente grato. <br />
							Ora camminavano di nuovo fianco a fianco, con Carlo che li precedeva di un passo e li guidava verso il Centro, dove dovevano passare tutti e tre per raggiungere le loro abitazioni. <br />
							Riccardo era molto eccitato. “Il fratello di Carlo è scappato in montagna dopo che gli è arrivata la cartolina” mormorò in un orecchio a Toni senza rallentare l’andatura. “Quando ci arriva facciamo lo stesso anche noi!”. Aveva ancora in mente le fantasie sui partigiani fatte al Rifugio, e l’essersi ricongiunto con Toni lo aveva incoraggiato ancor di più. <br />
							“Mi fa piacere vederti deciso” disse Toni “ma ci sarà tempo per pensarci” e lo guardò con un’espressione indecifrabile. Ma lui lo conosceva abbastanza per sapere che, per quanto gli piacesse fare il bastian contrario, al momento buono sarebbe stato fra i primi a fare quel che andava fatto. E tutto diventava più verosimile al pensiero di poterlo fare insieme. <br />
							Le strade si stavano rianimando, usciva dai portoni chi era rimasto nelle case, serrande venivano risollevate e fecero un salto quando azionando il campanello una donna chiese loro strada arrivando da dietro con la bicicletta. Ma girato l’angolo della GIL cominciarono a cogliere anche segni più marcati del bombardamento: cornicioni caduti, la strada seminata di pietre e una cortina di fumo e polvere che galleggiava a mezz’aria, si infittiva man mano che si avvicinavano alla ferrovia. E la gente formicolava e rimbalzava richiami concitati, un gruppetto stava intorno a un ferito steso a terra, di fronte a una parete butterata di schegge. Più oltre videro il primo edificio sventrato e anche gli altri intorno avevano le imposte mezze divelte e penzolanti. <br />
							Avevano cominciato a correre, quasi senza rendersene conto, imitando quelli che avevano intorno, penetrando sempre più nello scenario di distruzione. In via Cavour i palazzi abbattuti erano più di quelli rimasti in piedi, tanto mal ridotti anche questi ultimi che Riccardo non riuscì a capire se era stata distrutta o no la casa dove abitava la sua maestra delle elementari, che ogni volta lo incontrava per strada si fermava a parlare. <br />
							Volti terrei si aggiravano intorno a quello scempio e la gente correva o si fermava sbigottita fra i cumuli di macerie, intorno ai corpi riversi sulla strada. I tre amici si tenevano stretti per evitare lo sciamare delle persone. A momenti correvano e poi si arrestavano davanti a qualcosa, si guardavano atterriti e impotenti, riprendevano a correre. Tossivano, piangevano, gli occhi e la gola bruciavano per la polvere dei calcinacci e per il fumo degli incendi. <br />
							“Prendevano di mira la ferrovia ma hanno fatto un macello!” disse Toni, e Carlo li spinse avanti. Era il più agitato di tutti e solo allora Riccardo ricordò che la sua casa era a pochi isolati dalla stazione. <br />
							Era un crescendo di morte e desolazione. Di un caseggiato restava solo una quinta spalancata sul vuoto. Il palazzo a fianco si era accartocciato su se stesso come se avesse ricevuto un gigantesco pugno. Nella piazzetta ingombra di calcinacci, davanti alla fontana erano stese tre salme coperte da lenzuola. Passò un’autolettiga con la sirena spiegata, poi un sidecar con sopra due soldati tedeschi. Nei giardinetti diversi alberi erano stati sradicati. Nelle buche aperte dalle bombe la terra aveva il colore scuro dei campi appena arati. <br />
							Le bombe avevano mutato il volto della città. Occorreva uno sforzo continuo per ricostruire la forma di edifici e angoli familiari, divenuti irriconoscibili. <br />
							Ora si vedevano molte divise dei volontari dell’UNPA, che con pale e badili aveva cominciato a scavare intorno agli edifici crollati. I tre amici stavano passando accanto a uno di quelli quando si sentì uno scricchiolio, un fragore sordo che sembrava salire dalla terra, e in un turbine di polvere il mucchio di macerie cambiò forma mentre si alzavano alte le grida di quelli che gli stavano più vicino. <br />
							Era il peggior bombardamento che si fosse mai visto. Dovevano esserci decine di morti, centinaia forse e loro passavano attraverso quel disastro ciascuno correndo con la mente ai propri cari, alla propria casa, costretti dallo spettacolo che avevano davanti a evocare le tragedie peggiori. Riccardo si ripeteva che i suoi dovevano per forza essersi messi in salvo al Rifugio dell’ospedale, che avevano avuto a disposizione più tempo di lui per farlo, che di lì a poco avrebbe potuto riabbracciarli. Ma bastava il pensiero del peggio per sentirsi mancare il terreno sotto i piedi, mentre si erano allontanati di colpo i pensieri sul futuro, sulla fine della guerra e sulle imprese dei partigiani a cui avrebbero potuto riunirsi. C’era bel altro prima. C’erano da scampare disgrazie irreparabili. Gli sembrava ridicola e quasi colpevole ora l’ansia provata al Rifugio, di rivedere Toni e liberarsi della vergogna per la fuga sotto le bombe. <br />
							La strada fu bloccata per qualche istante da un’autolettiga che caricava un ferito, poi proseguirono sguazzando sulla carreggiata che grondava acqua, per qualche conduttura saltata. Erano ormai a un isolato dalla casa di Carlo che infatti si era staccato da loro e correva avanti. <br />Accelerarono il passo ma lo raggiunsero solo quando si era già fermato, attonito, davanti a quello scenario incredibile. Si schermava gli occhi con la mano, per ripararli dalla luce abbacinante o forse per non vedere ciò che lo sguardo era incapace di accettare e comprendere. <br />
							La casa di Carlo non esisteva più. Al suo posto un cielo che prima non c’era, e che mai avrebbe dovuto esserci, apriva spazi impudichi sugli edifici retrostanti, che sembravano rattrappiti per la vergogna di essere stati scoperti. <br />
							C’erano dei volontari che scavavano intorno alle macerie e altre persone affannate intorno. Carlo si accostò tremante a una donna che si voltò, lo riconobbe, lo abbracciò e gli disse che i suoi erano salvi. Poi scoppiò in lacrime e aggiunse che invece erano rimasti là sotto gli anziani inquilini del primo piano, che come al solito si erano rifiutati di scappare al Rifugio. <br />
							Carlo rimase per qualche istante immobile, paralizzato dalle emozioni che gli avevano suscitato quelle parole. Poi con uno scatto improvviso prese dalla tasca la chiave di quella che era stata la sua casa e la scagliò con rabbia contro il mucchio di rovine, contro lo scorcio di cielo nuovo e impossibile che si era aperto come un baratro nella sua vita. <br />

						</div>
						<div class="modal-header">
							<h5 class="modal-title">The sky that wasn’t there before - Alessandro Tamburini</h5>

						</div>
						<div class="modal-body-testionline p-4" id="result">
				
				"I'm telling you that things are going to change! They've already begun to change," said Riccardo, stopping to give more weight to his words. Toni stopped as well, with his head sunk into his shoulders and his hands dug into the pockets of his trousers.
				 "I wouldn't be so sure," he responded,  and with his tongue he toyed with a blade of grass that he had put into his mouth shortly before.
				 "Just think that fascism is over! Do you think that's nothing?" insisted Riccardo, raising the tone of his voice.
				 "Well, but there is still the Republic of Salò, and we have the Germans here ...," objected Toni.
				 "The Anglo-Americans are coming; they have already won the war, it's just a matter of time ... "
				 "Yes, but we've lost it," Toni remarked, and Riccardo, getting really animated, said:
				"The war will soon be over, and that's what matters! And everything can begin again! Don't  you understand what that means!?"
				 Toni walked on and Riccardo gave him a huge punch in the shoulder, releasing all his irritation.
				"You pig-headed jinx!" he said, and Toni, feeling the blow, rubbed the muscle in his sore arm.
				 Sometimes Riccardo had those outbursts of intolerance towards his friend. Toni was a bright guy and he liked talking to him just because he had always his own way of looking at things, which was not like that of  most people. Yet he had the tendency to always see the black side of things, the deception, the rip-off, the maggot that bores its way even into the most beautiful apple. And when he took a certain stand, there was just no way to move him, even by an inch.
				 They had always known each other, ever since they had worn short trousers even in winter and had shared the games at St. Peter's Youth Club:  never-ending football matches on the dusty pitch in summer, and in winter table-football, billiards and picture-cards, with Father Ettore who, with huge baskets of croissants, enticed them to attend Mass and fought the daily battle of giving them at least a smattering of religion.  When they were twelve, they were both given bicycles as presents, and it was during those long and sweaty cycle rides that an exclusive friendship had ripened, that would never dwindle. Then, they had gone to the same secondary school, and started going out with girls, with lots of  embarrassment and wavering, followed by hours of good laughs and great complicity between the two of them, while they commented on and chatted about the first yearned for, and mostly missed, conquests. Even then, one could see Toni's critical streak as he mercilessly shattered his friend's romantic frenzy and, even worse, more than once it would happen that the girls preferred him, he who never fell in love with anyone.
				Riccardo stopped where the path that they had taken widened into an asphalt road, on the outskirts of the town. He cast a long look at the roofs of the houses, bright red in the vivid morning light, and then took the tobacco from his pocket and rolled himself a cigarette. Toni gestured he wanted one as  well, and Riccardo handed him the tobacco while he lit up striking  the match on a wall. Then he lit Toni's cigarette from his own, so as not to waste a second precious match, and together they avidly took the first drags.
				They had had this vice for some time now and saved on everything to scrape up the money needed to keep it up, but they still didn't dare light a cigarette at home in front of their parents. Once when Toni's father had caught him on the street with a cigarette in his mouth, he had sent it flying with a slap which had left Toni stunned for half an hour.
				 But now at seventeen, they were and felt grown up, especially when they were on their own, when they felt they had their own lives in their hands.
				 They had arranged to meet that Sunday morning, at the time when they used to go to mass, for one of their usual walks outside the town, along the path that skirted the river. They had stopped as usual to smoke and chat on a wooden pier that parted from the bank, where the river formed a kind of inlet. First they had talked about girls, even though they had nothing serious going at that moment, but on the way back the conversation turned to the events of recent months, with the endless speculations about when the war would end and what would happen afterwards.
					  "I don't think it will end very quickly..." said Toni, as if in the long interval he had never detached himself from those thoughts. "Italy is long and the Germans are making life difficult for the Allies. It will take at least another winter, you can be sure".
				 Riccardo  made the gesture of getting rid of that time as if it were a bothersome insect. He said:
				"If there is to be another winter, it will go by too. The moment will come, and nothing will ever be the same again!"
				 Toni shook his head:
				"What do you expect will happen? They will always be in command..."
				 "Who they?"
				"The ones who have always ruled, with or without fascism, in war and in peace: the owners of the industries, of the banks, the ones who these days have white bread and fruit on the table, and as many good cigarettes as they want...".
				 "You'll never change, you always have to see the dark side," Riccardo spoke out. "The end of the war is a dream and only you cannot manage to see it. I say that we will celebrate for a whole year!".
				 "It will bring an end to lots of suffering, I know that well," Toni admitted, "but it will only be the beginning, with all that will have to be done afterwards".
				 They had reached the crossing that led into the centre, to the café where they usually met up on Sundays at that time.
				
				"Politics will come in due course," he replied. "Now let's think about getting rid of the Germans and then...".
				 The words stuck in his throat because at that very moment the wail of the siren was heard, very loud since they were just a few blocks from the Town Hall.
				 Immediately the people around them quickened their step, some were already running off and they too began to hasten, without knowing where. Toni peered at the sky, looking southwards, while with an instinctive gesture Riccardo hunched  his shoulders, as if already sheltering himself from the bombs. He said:
				 "For the last few days it seemed as if they wanted to leave us in peace".
				 "It seemed..." Toni remarked, and he immediately pointed to the mountains, and a second after in the clear blue sky, the twinkling of a nacelle flashed, while the dull rumbling of the engines could be heard.
				 Everyone was running now, and the clamour of the shouts and cries echoed between the windows and the streets. Shutters slammed and were closed, a couple of steps away the rolling shutter of a café was pulled down with a great clang and it made them start as if it was the first bomb. They began to run too, still without any clear direction, almost skipping and keeping looking at each other to take a hurried decision.
				 "My folks are all at home at this time and in two minutes they can get to the Shelter of the hospital!" said Riccardo, panting more because of  anxiety than of the run.
				 "Ditto for my folks," said Toni, "but we won't make it as far as there. The Shelter of San Martino is nearer".
				 "Well let's go there" said Riccardo, setting off first with a real and determined run, and immediately turning round to check that his friend was following him.
				 The rumbling of the aeroplanes got louder and louder, it seemed that it was going to pull down the sky and Riccardo ran on with his head down. He knew there was little time, a few minutes, maybe even only seconds. He knew that the aircrafts went much faster than he did.
				 Others were running behind and in front of them, almost all in the same direction because the Shelter of San Martino was everyone's destination. He told himself that they would arrive in time, that they would make it like other times, even if each time could be the one when the house would be blown up or one of his nearest and dearest could fall victim. He must not think about it. He must only run and hope that the harm would be as little and as far away as possible.
				  They ran, passing block after block. The Shelter was now just a few hundred metres away, and Riccardo felt as if he already had the typical smell of mould and damp ground in his nostrils. But as they were turning the corner of the pharmacy Toni suddenly stopped, and he had to do the same, placing a hand on his aching spleen.
				 "Come on, we're nearly there," he said, but Toni hadn't stopped because he was tired;  something important had suddenly flashed into his mind and he worded it:
				"Silvestro is at home with a broken ankle. He and his mother might need help!"
				
				Riccardo immediately pulled back and said:
				 "And what do you want us to do about it? We cannot possibly carry him bodily to the Shelter!"
				 Toni kept on looking at him, but he had already begun to step back as if he had already decided.
				"It's better to go and check," he replied.
				"You know they always go down to the cellar! They'll manage like that this time as well!" he tried to insist, but as expected Toni had no intention of backing out of his proposal, and said:
				 "We'll be there in two minutes. Then, if anything, we can go down into the cellar with them".
				 "Two minutes, you must be joking," he retorted, while he felt the panting in his chest much stronger than when he was running. "The bombs will arrive first! Do you want to kill yourself! Listen to me, let's go the Shelter!"
				 "It doesn't matter, I'll go on my own. We'll meet up when it's all over," the other concluded, and a kind of dizziness swept through Riccardo, in which were mixed anxiety and pride, loyalty and fear. He hated Silvestro and the ankle that he had stupidly broken a couple of weeks before, and tried to convince himself that their help wasn't needed. A voice kept telling him not to separate from his friend, another to run on to the Shelter, and he felt as if he were paralysed, incapable of ordering himself to set off in either direction. But the strongest voice was that of the first long whiz, shortly followed by a boom that exploded still far away, but that smashed the window-panes of the houses nearby into bits. And as if he had cut off at a stroke the hawser of moorings, while Toni was still hesitating as if to give him the last chance to follow, Riccardo dashed off headlong in the direction of the Shelter, along the road now practically deserted, zigzagging from the protection of one building to another, as though this was enough to avoid the bombs which any moment could pour down on his head.
				He raised his eyes for an instant and saw two aeroplanes quite near, lower than the mountains, with the hatches wide open and the clusters of bombs which rolled as they fell down. He was puffing and blowing, and the air was burning in his throat and lungs. Then he saw the far-off Shelter and right then infernal whizzes and rumbles exploded over the town, followed by the noise of collapsing buildings and shattered glass that poured down on the road. In that din he felt he couldn't hear anything, and he desperately leapt over the last bit.
				A violent rumble was heard nearby and the ground shook. He tripped, and only by miracle did he manage to stay on his feet, and he was almost there, he saw the squat vault that was the entrance to the Shelter and he dived in, almost rolled down the worn stone steps to the heavy main door that someone had just closed, he threw himself against it and started thumping, and the door was opened again, he was pulled inside by a dozen hands, ended up slumped on the ground in the confusion of the last to arrive like himself, still crowded in the cramped entrance.
				 He remained as if he were in a stupor for a few seconds, with his  heart pounding and the sweat dripping down his back. Then he came back to his senses and immediately pictured Toni before him, and the moment when he had rashly separated from him.
				 It was a verdict without appeal. He had deserted him, at least he should have the courage to admit it. Maybe Silvestro really needed their help, and anyway he should not have split up from Toni. Fear had paralysed his reasoning and made him behave as he did.
				 Meanwhile he began to look around, in that Shelter he didn't know. He had been inside only once, because of  an unusual afternoon alarm that had caught him by surprise on his own in that area. It was bigger than the one at the hospital, the ceilings were higher, but there was the same rotten stink, the same damp air, almost wet, that was now cooling down the sweat in his sticky clothes. He got to his feet and elbowed his way through those who had stopped at the entrance, and who trembled and held on to each other when they heard a hissing not far from the Shelter, followed by the rumble of the bomb that thundered deeply, making the beams propping the vault creak
				 He waited for a moment of calm and then went into the wider area, a kind of tunnel almost a hundred metres long, with two rows of benches along the sides. It was full of people, the men on their feet, the children on their mothers' laps, the old women with the rosary beads held tightly between their fingers and their lips bent in an endless muttering. He made his way through it, his gaze sweeping around to see if there was someone he knew. At the hospital Shelter he always found himself   among friends and relatives, but here all he saw were strangers, and in that moment he would really have liked to have  a chat with somebody.
				 He couldn't forgive himself for his cowardly act, and he couldn't cope with the idea that something might have happened to Toni, because then what he had done would really become a crushing burden.
				He pushed further forward, ploughing through the crowd that like a single body huddled together and held its breath when the whiz of a bomb sounded and, after it had fallen, began to breathe again and each time a murmur of relief and whispered comments arose. He was afraid of the bombs too, but like the other times he felt quite safe in the Shelter, even when the walls shook and dust and rubble fell down from the ceiling.
				 He peered into the dormitories that opened off from the sides of the tunnel, like chapels of a church, and inside there were benches and mattresses thrown on the wooden floor, and lots of people sitting in rows, huddled together like on the tram, and everyone stared at him when he put his head in to see if there was anyone he knew.
				 At last, when he got back to the main tunnel, he saw the back of a curly head and  he thought he recognised the person, and in fact it was just him.
				 "Hello Carlo, are you here with your brother?" he asked going alongside him.
				"No, I was at my aunt's when the alarm went off and I came here with her, but now I've left her with her friends", he replied and he gave him a slap on the shoulder to show he was happy to see him.
				"But you're out of your area," he added, and Riccardo just nodded because, in fact, he was wondering if he couldn't tell Carlo about what had happened between him and Toni. He told himself he couldn't, because he was ashamed and he wasn't close enough to him.
				 Carlo had attended Middle School with him, but they had continued to meet up, also with his older brother who sometimes was part of their group and was a nice fellow, he didn't give himself airs as the older ones always did. Everyone knew that their father had had problems with the fascists, already before the war, and that they had gone to get him at home in the middle of the night. Riccardo's mother said that he shouldn't go out with them because they were hot heads, but he didn't pay any heed. He liked the fellows he could talk to and after Toni, they were the best.
				 A bomb whizzed close by and he saw many people holding their heads in their hands waiting for the rumble, there was the explosion and it echoed  like thunder between the walls. Then they could hear the intermittent bursts of the anti-aircraft fire.
				Carlo pulled him by the arm and pointed to him to sit down with him in an empty corner, between two benches pushed to the walls. But there wasn't enough room for both and so he hunkered down beside him, close to a tiny little girl, who with one hand held on to her mother's skirt, and with the other grasped a rag doll. He smiled at her and she rocked her doll, but never changed the serious expression on her face.
				 "They're bombing more and more often. That means they're preparing the offensive," Carlo said; he nodded and said:
				 "The other night Pippo fired at the power station and struck also the house of the Rensi's who live nearby".
				They began to exchange news about friends and acquaintances and about the many people who had been evacuated, after the heavy bombing the month before.
				"The evacuation is a good thing also for those who stay," Carlo said. "Before, twice the number were filled here; we were packed like sardines". Talking they held off anxiety and coped with the stifling air of the Shelter. They fell silent like everyone else and held their breath, when a new whiz was heard.
				 He was happy to have found someone to talk to. He replied to the point but he was still concentrating on when it would all end and he would be able to go out, see Toni safe and sound, and then he wouldn't care less about his inglorious flight, he was even ready to have his leg pulled and admit: yes, when I heard the first bomb whizzing I got scared, and they would all laugh and everything would go back to normal. That was what he wanted with all his heart.
					 A shriller and prolonged whizzing filled the eyes of the people around with fear, and raised the tone of the prayer an old woman was reciting with her rosary beads around her fingers.
				 "They want to cut off the supplies to the Germans," said Carlo raising his eyes to the ceiling, " then they'll advance, and so we'll have to do something as well".
				 He was still thinking of Toni and for once he felt like answering as Toni would have done.
				 "They might come to free us, but in the meantime they're throwing bombs on our heads", he said. "They're killing us and in the end we'll have to even thank them".
				 Carlo  responded with a firm expression, then lowering his voice said:
				 "If I get a call-up paper to join up with the Republic, I won't present myself, I'll go to the mountains and stay up there".
				 "The call-up papers for the contingents '24 and '25 have just arrived," Riccardo reminded him. "We're the next ones...".
				 Carlo bent his head towards him and lowering his voice, murmured:
				 "My brother got it as well. And he didn't present himself. He went with the partisans. He's been away for five days".
				 These words had a strong effect on him. He didn't expect things to already be at this point, that the time for decisions was so near
				 With the partisans..." he repeated. "And how did he manage it?"
				 "He went up to the village where my grandparents live. He was told he could get in contact with them there. And in fact he has never come back. We're waiting for news. My mother is terrorised but I say he did right".
				 There had been rumours about the partisans for some months. Many of those who had come back home after the 8th. September had hidden in the mountains so as not to be caught by the Germans, but at the beginning they had just gone into hiding and escaped the roundups. Then the news of the first actions had arrived: a fuel depot blown up, a punishment expedition against a spy, and just a week before they had learned about an action in the next province, an ambush aimed at a German truck, in which an officer of the Wermarcht had been seriously injured. But these were still distant and unreal events for Riccardo, and he couldn't imagine joining up with them, he who had never held a weapon in his hands, and had still never slept a night away from home. He had also talked to Toni about it, and even he didn't have very clear ideas. However, they had agreed that to wear the uniform and to go and fight again for the Duce wasn't right, in fact it wasn't even to be mentioned. This meant that within a short time, months or even less, something that still couldn't be imagined would happen one way or another.
					  "I have never held a weapon in my hands in my life," he murmured to put into words just one of the many doubts going through his head, and he thought that if he wanted to join up with the partisans he would have to improve, because they wouldn't know what to do with somebody who took to his heels at the first shot.
				 "They teach you," said Carlo. "They've got the guns taken from the enemy during the actions. Then I've heard that the Allies drop down from the aeroplanes arms, provisions, everything that is needed".
				 The whizzing and explosions had stopped now for a few minutes and the people had begun to make noise and were impatient to get out. At last the siren with the all-clear signal went off and the door of the Shelter began to swallow up the small crowd of people grouped together. It took Carlo and himself  considerable time to reach the exit and finally they saw the sunlight again, which made them blink after the gloom of the Shelter. There was a strange silence in the air, at the end of which the dull rumble of  the aeroplanes faded as they returned to where they had come from.
				 The people streamed along anxiously, hastening their step towards their  homes to see what condition they were in, to be reunited with their families from whom they had been separated when the alarm had gone off, and Carlo and he did the same. They walked on  sheets of broken glass but he didn't think he saw great changes,  though it wasn't easy to distinguish between the damage of  the previous bombings and that of the last one. And almost immediately his heart leaped when he saw in the distance his friend Toni, unmistakeable with his long thin legs and sharp  features that seemed to  rend the air. He was striding along towards  the Shelter and as soon as he saw him, his face lit up and he ran to meet him. There was a never-ending embrace, with guttural sounds which  didn't manage to take the form of words.
				"You were right," said Toni, "Silvestro was already in the cellar and I stayed with him and his mother. At a certain point the rumble got so loud that it seemed as if the house was about to fall on our heads!"
				 "What areas have been hit?" he anxiously asked, and Toni replied:
				 "I heard they targeted the station but I haven't been there yet. I wanted to see your ugly face without a scratch first", and  all of a sudden he felt a weight lifted from him. Maybe it hadn't been as serious as he thought, or else Toni had forgiven him and that was it, it didn't matter. Toni hadn't made a big deal of it and he was immensely grateful to him.
				 Now they were walking once more side by side, with Carlo walking one step ahead and leading them towards the Centre, where all three of them had to go to get to their homes.
				 Riccardo was very excited. "Carlo's brother fled to the mountains after he got the call-up papers," he murmured into Toni's ear, without slowing down his pace. "When we get them, we'll do the same thing!". His reflections about the partisans at the Shelter were still going through his mind, and being reunited with Toni had encouraged him even more.
				 "I'm happy to see you so determined," said Toni, "but there'll be time to think about it", looking at him with an  inscrutable expression. But Riccardo knew him well enough to  understand that, no matter how much  he liked to be  bloody-minded, at the right moment he would be one of the first to do what had to be done. And everything became more likely at the thought of  doing it together.
				 The streets were coming to life again, the people who had remained in their houses were coming outdoors, shutters were being rolled up, and they jumped when a woman on a bicycle came up behind them, ringing the bell to make way. But when they turned the corner of the GIL, they began to notice even more obvious signs of the bombing: fallen cornices, the street strewn with stones, and a curtain of smoke and dust floating in mid air and getting thicker and thicker as they neared the railway.   And the people swarmed around and agitated calls resounded, a small group was standing around an injured person stretched out on the ground, in front of a wall pitted with bullets. Further on they saw the first gutted building, and the shutters of the other buildings unhinged and hanging down.
				They had begun to run, almost without realising it, imitating the people around them, entering deeper and deeper into the scenario of destruction. In via Cavour, there were more buildings knocked to the ground  than left standing, and these latter were in such a bad state that Riccardo couldn't manage to understand whether they had destroyed the house of his primary teacher who, every time they met on the street, would stop to talk to him.
				 Ashen faces were wandering around that havoc and people were running or stopping aghast among the heaps of rubble, around the dead bodies on the road. The three friends kept huddled together to avoid the swarming of people. Sometimes they would run and then stop in front of something, look at each other terrified and helpless and then start to run again. They coughed and cried, and their eyes and throats burned with the dust from the rubble and the smoke from the fires.
				"They were aiming at the station but they've wreaked havoc!" said Toni, and Carlo pushed them ahead. Carlo was the most worried of them all, and only then did Riccardo remember that his house was just a few blocks from the station.
				 It was a crescendo of death and desolation. Of one block, only a wing remained gaping into the open. The building beside it had collapsed as if it had been struck by an enormous blow. In the small square cluttered with rubble, three corpses covered with a sheet were stretched out in front of the fountain. An ambulance with the siren wailing passed by, and then a sidecar with two German soldiers on it. Many trees had been uprooted in the gardens. In the holes, blown open by the bombs, the soil was the same dark colour as fields just ploughed.
				 The bombs had altered the look of the city. It took continuous effort to reconstruct the shape of the buildings and familiar corners, now unrecognisable.
				 Now many uniforms could be seen of the volunteers of the UNPA, who with shovels and spades had begun to dig around the collapsed buildings. The three friends were passing next to one of them, when a creaking was heard, a  dull crash that seemed to come up from the ground, and in a whirl of dust, the heap of rubble changed shape, while the people nearest started screaming.
				 It was the worst bombing that had ever been seen. There must have been dozens of deaths, hundreds maybe, and they were passing through that disaster, each with his own house and his dear ones fixed in his mind, forced by the scene around them to envisage the worst tragedies. Riccardo kept repeating to himself that his folks must have found refuge in the Shelter of the hospital, that they had had more time than him to get there, and that shortly he would be able to hug them again. But the thought of the worst was enough to feel the ground slipping from under his feet, while all of a sudden thoughts about the future, the end of the war, the  feats of the partisans that they could join, faded. There was more to come first; there was to escape irreparable trouble. Now the anxiety he had felt at the Shelter about seeing Toni again and about his shame for his flight under the bombs, seemed ridiculous, and he even felt a bit guilty about it.
				
				The road was blocked for some moments by an ambulance picking up someone wounded; then they went on, paddling along the road streaming with water from a pipeline that had been blown up. Now they were just a block from Carlo's house, and in fact he had run on ahead. They speeded up but they caught up with him only after he had stopped, dumbfounded, in front of the incredible scenario. He was shading his eyes with his hand, to protect them from the blinding light, or maybe so as not to see what his mind could not accept and understand.
				 Carlo's house didn't exist any more. In its place a sky that wasn't there before, and that should never have been there, opened indecent views on the buildings behind, which seemed numbed by shame for having been unveiled.
				There were volunteers digging around the rubble and other people there frantic. Trembling, Carlo went over to a woman who turned round, recognised him, hugged him and told him his folks were safe. Then she burst into tears and added that instead, the old people on the first floor, who as usual had refused to flee to the Shelter, had remained there underneath.
				 For some moments Carlo stood immobile, paralysed by the emotions those words had stirred. Then, with a sudden jerk he took from his pocket the key to what had once been his house and angrily hurled it at the heap of rubble, at the view of new and impossible sky that had opened like an abyss in his life.
				
				
				
				Alessandro Tamburini
				Luglio 2007
				
				Traduzione di Irene Diamond
				
				
				
				
				
				
				
				
			</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-dismiss="modal">CHIUDI</button>
						</div>
					</div>
				</div>
			</div>


		)
	}
}

import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                  <div className="col-md-6">
                    <div className="blog-entry">
                    <h2 className="colorlib-heading">Nota Biografica di Alessandro Tamburini</h2>
                      <img src="images/foto/foto_notabiog.jpg" className="img-responsive" alt="book cover" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-desc">

                      {/* <span className="heading-meta">About Us</span> */}
                      
                      <p>Nato in Trentino da genitori emiliani, Liceo Classico a Cesena e Laurea in Filosofia a Bologna, è vissuto fra diverse città, per lo più sull’asse Brennero–Rimini. Ha due preziosi figli, in ordine di apparizione: Emilio e Maddalena.</p>
<p>Dando seguito a una tradizione di famiglia (nonno liutaio, madre chitarrista, sorella compositrice e docente di musica elettronica) si è occupato di musica contemporanea e nel 1986 ha pubblicato il saggio Il calcolatore e la musica (Franco Muzzio Editore, Padova).</p>
<p>Ha esordito nella narrativa nel 1988 con la raccolta di racconti Ultima sera dell'anno (Il lavoro editoriale).</p>
<p>In seguito ha pubblicato le raccolte di racconti Nel nostro primo mondo (Marsilio, 1990, Presentazione di Pier Vittorio Tondelli, Premio Settembrini), La porta è aperta (Marsilio, 1994), Uno sconosciuto alla porta (Pequod, 2008, Premio Comisso), Ultimi miracoli (Pequod 2022), nonché i romanzi Le luci del treno (Marsilio, 1992, Premio Sirmione-Catullo), L'onore delle armi (Bompiani, 1997, Premio Città di Catanzaro, Premio Grinzane Cavour), Due volte l'alba (Marsilio, 2002, Premio Circeo), Bagaglio leggero (Pequod, 2006), Quel che so di Adonai (Italic-Pequod, 2010), Giostra primavera (Pequod 2018). Nel 2012 ha pubblicato la biografia Italo Allodi, Ascesa e caduta di un principe del calcio edito da Italic-Pequod. In due anni di lavoro insieme ha scritto con Soma Makan Fofana la biografia Quando la terra scotta. Vita di un giovane africano dal Mali al Trentino (Pequod, 2019).</p>
<p>Suoi racconti sono apparsi in numerose antologie, tra cui Italiana, antologia dei nuovi narratori (Mondadori 1991, poi negli Oscar 1996), Patrie impure (Rizzoli 2003), Fuochi (Feltrinelli 2024).</p>
<p>Fondatore dell'Associazione culturale "Spazio letterario", fra gli anni ottanta e novanta ha promosso a Trento convegni di respiro internazionale, e anche in seguito ha tenuto incontri e conferenze, corsi di scrittura e gruppi di lettura, in Italia e all'estero. Nel 1990, invitato da Pier Vittorio Tondelli, ha contribuito alla nascita della rivista “Panta. I nuovi narratori”, edita da Bompiani, per cui ha poi scritto diversi testi.</p>
<p>Nel dicembre 2005 è stato lo scrittore chiamato a rappresentare l'Italia a Malta al convegno internazionale sulla novella "Malta International Short Story Festival" e fra il 2007 e il 2008 ha partecipato al progetto europeo "Overcoming dictatorship. The encounter of poets, artists and writers", con incontri e reading a Dresda, Walbrzich, Praga, Budapest, Bucarest e la pubblicazione di un'antologia di racconti degli autori partecipanti: “Present tension” (Centre European University Press, 2009).</p>
<p>Nel triennio 2012-2014, presso la Scuola di Dottorato della Facoltà di Lettere dell'Università di Trento, ha dato corso a un progetto di ricerca incentrato sulla figura e l'opera dello scrittore Beppe Fenoglio, culminato con la pubblicazione del volume L'uomo al muro. Fenoglio e la guerra nei Ventitre giorni della città di Alba (Italic-Pequod, 2016).</p>
<p>Ha scritto sceneggiature per cinema e televisione, tra cui numerose serie di animazione, divertendosi soprattutto con quelle tratte da opere letterarie (“Sandokan” da Salgari, “Kim” da Kipling, “L'Isola del tesoro” da Stevenson).</p>
<p>Attualmente è editorialista del quotidiano l’Adige e collaboratore dei quotidiani Avvenire e Corriere Romagna, oltreché di diversi programmi di RAI Radio3.</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </section>

      </div>

    )
  }
}
